<div *ngIf="carregarGridCronograma || (!_fromModalListagemPpb && _existePontuacaoMinima)" class="row form-group" style="margin-left: 2px;">

  <div [ngClass]="(validacao.pendenciaGrupoPPB) ? 'pendencia-validacao' : '' ">
    <a
      style="margin-right: 10px; margin-top: 5px;"
      class="btn btn-primary btn-sm pull-right"
      data-toggle="tooltip"
      title=""
      (click)="incluirPontuacaoMinima()"
      *ngIf="!_fromModalListagemPpb && !_isPorPontuacao"
      data-original-title="">
      <i class="fa fa-plus"></i> Incluir na Lista

    </a>

    <app-grid-cronograma-ppb
      [(dataVigenciaInicialPPB)]="dataVigenciaInicialPPB"
      [(idPPB)]="idPPB"
      [(habilitarInputs)]="habilitarInputs"
      (atualizaListagemGrid)="atualizaListagem($event)"
      [(isAlteracaoParaRevogacao)]="isAlteracaoParaRevogacao"
      [(lista)]="grid.lista"
      [(total)]="grid.total"
      [_isPorPontuacao]="_isPorPontuacao"
      [(parametros)]="parametros"
      [(isRetificacao)]="isRetificacao"
      (onChangeSort)="onChangeSort($event)"
      (onChangeSize)="onChangeSize($event)"
      (onChangePage)="onChangePage($event)"
      *ngIf="!_isPorPontuacao"
      [(formPai)]="form"
      [isVisualizar]="_fromModalListagemPpb"
    >
    </app-grid-cronograma-ppb>

  </div>
</div>

<app-modal-incluir-cronograma
  [(idPPB)]="idPPB"
  (atualizaListagem)="atualizaListagem($event)"
  #appModalIncluirCronograma
>
</app-modal-incluir-cronograma>


