import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InformationService } from '../../../services/information.service';
import { environment } from '../../../../../environments/environment';

export interface AlertaModel {
  title: string;
  message: string;
}

declare var hcaptcha: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal-recaptcha.component.html',
})
export class ModalRecaptchaComponent implements OnInit, AfterViewInit {

  isCaptchaResolved: boolean = false;
  public sitekey: string;
  private bypass: boolean = false;
  public botaoTexto: string = 'Fechar'; // Variável para controlar o texto do botão

  constructor(
    public dialogRef: MatDialogRef<ModalRecaptchaComponent>,
    private informationService: InformationService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AlertaModel
  ) {
    const informacao = this.informationService.get();
    this.sitekey = environment.hcaptchaSiteKey;
    this.bypass = false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    hcaptcha.render('hcaptcha', {
      sitekey: this.sitekey,
      callback: this.captchaResponse.bind(this),
    });
  }

  public captchaResponse(response: string): void {
    this.isCaptchaResolved = true;
    this.botaoTexto = 'Validado'; // Altera o texto do botão para "Concluir"
    this.cdr.detectChanges(); // Garante que a detecção de mudanças ocorra
  }

  public reset(): void {
    this.isCaptchaResolved = false;
    hcaptcha.reset(); // Reseta o hCaptcha
  }

  public isValidToken(): boolean {
    if (this.bypass) {
      return true;
    }
    return this.isCaptchaResolved;
  }

  public fecharModal(): void {
    this.dialogRef.close(this.isCaptchaResolved); // Fecha o modal
  }
}
