import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModalConfirmacaoComponent } from '../components/modal/modal-confirmacao/modal-confirmacao.component';
import { ModalErrorComponent } from '../components/modal/modal-error/modal-error.component';
import { ModalSucessComponent } from '../components/modal/modal-success/modal-sucess.component';
import { ModalInformacaoComponent } from '../components/modal/modal-informacao/modal-informacao.component';
import { ModalRespostaSucessComponent } from '../components/modal/modal-resposta-sucess/modal-resposta-sucess.component';
import { ModalRespostaErrorComponent } from '../components/modal/modal-resposta-error/modal-resposta-error.component';
import { ModalJustificativaComponent } from '../components/modal/modal-justificativa/modal-justificativa.component';
import { ModalRecaptchaComponent } from '../components/modal/modal-recaptcha/modal-recaptcha.component';


@Injectable()
export class ModalService {

	constructor(private dialog : MatDialog){ }
	
	confirmacao(mensagem: string) : Observable<boolean> {
		
		var info : any = {};
		info.message = mensagem;

		var _modalAlerta = new ModalConfirmacaoComponent(info);	

		return this.dialog.open(ModalConfirmacaoComponent, _modalAlerta).afterClosed();
	}

	error(mensagem: string, titulo: string) : Observable<boolean> {	

		var info : any = {};
		info.title = titulo;
		info.message = mensagem;

		var _modalAlerta = new ModalErrorComponent(info);		

		return this.dialog.open(ModalErrorComponent, _modalAlerta).afterClosed();
	}

	sucesso(mensagem: string, titulo?: string) : Observable<boolean> {

		var info : any = {};
		info.title = titulo;
		info.message = mensagem;

		var _modalAlerta = new ModalSucessComponent(info);	

		return this.dialog.open(ModalSucessComponent, _modalAlerta).afterClosed();
	}

	/* Modais de informação apenas exibirão MENSAGENS! */

	informacao(mensagem: string, titulo?: string) : Observable<boolean> {

		var info : any = {};
		info.title = titulo;
		info.message = mensagem;

		var dialogRef : MatDialogRef<ModalInformacaoComponent>;
		var _modalAlerta = new ModalInformacaoComponent(dialogRef, info);	

		return this.dialog.open(ModalInformacaoComponent, _modalAlerta).afterClosed();
	}

	infoSucess(mensagem: string, titulo: string) : Observable<boolean> {

		var info : any = {};
		info.title = titulo;
		info.message = mensagem;

		var dialogRef : MatDialogRef<ModalInformacaoComponent>;
		var _modalAlerta = new ModalInformacaoComponent(dialogRef, info);	

		return this.dialog.open(ModalRespostaSucessComponent, _modalAlerta).afterClosed();
	}

	infoError(mensagem: string, titulo: string) : Observable<boolean> {

		var info : any = {};
		info.title = titulo;
		info.message = mensagem;

		var dialogRef : MatDialogRef<ModalRespostaErrorComponent>;
		var _modalAlerta = new ModalRespostaErrorComponent(dialogRef, info);	

		return this.dialog.open(ModalRespostaErrorComponent, _modalAlerta).afterClosed();
	}

	justificativa(title: string) : Observable<any>{

		var info : any = {};
		info.title = title;
		info.message = "";

		var dialogRef : MatDialogRef<ModalJustificativaComponent>;
		var _modalAlerta = new ModalJustificativaComponent(dialogRef, info);	

		return this.dialog.open(ModalJustificativaComponent, _modalAlerta).afterClosed();
	}

	infoRecaptcha(): Observable<boolean> {
		const dialogRef = this.dialog.open(ModalRecaptchaComponent, {});
	

		console.log(dialogRef);
		return dialogRef.afterClosed();
		
	  }
	  




}
