import { Component, Injectable, OnInit, ViewChild, Input } from "@angular/core";
import { PagedItems } from "../../../../../view-model/PagedItems";
import { MessagesService } from "../../../../../shared/services/messages.service";
import { LoadingService } from "../../../../../shared/services/loading.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from "../../../../../shared/services/application.service";
import { ChangeListagemProdutosService } from '../../../../../shared/services/changeListagemProdutos.service';
import { FiltrosModalListagemVM } from "../../../../../view-model/FiltrosModalListagemVM";
import { ServiceTipoListagemVM } from "../../../../../view-model/ServiceTipoListagemVM";

@Component({
	selector: "app-produtos-vinculados",
	templateUrl: "./produtos-vinculados.component.html"
})

@Injectable()
export class ProdutosVinculadosComponent implements OnInit {

	gruposPPB : any ={};
	infoGrupos : any ={};
	form = this;
	path : string;
	grid: any = { sort: {} };
	parametros: any = {};
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	isBuscaSalva : boolean = false;
	objetoSession : any = {} = null;

	@Input() dataVigenciaInicialPPB: string;
	@Input() habilitarInputs: boolean;
	@Input() _fromVinculos: boolean = false;
	@Input() _isSempontuacao: boolean;
	@Input() _isPorPontuacao: boolean;
	@Input() _isPorGrupo: boolean;
  @Input() _fromModalListagemPpb: boolean = false;
	@Input() validacao: any = {} = null;

	servico = 'ProdutosVinculados';

	@Input() idPPB: number;
	@Input() buscarVinculos: boolean;
	@Input() _mostrarCampoDocumento?: boolean = false;
	@Input() _isModalVinculo: boolean;
  @Input() isConsultaFiltradaModalListagem: boolean = false;
  @Input() objConsultaFiltradaModalListagem: FiltrosModalListagemVM;

	@ViewChild('appModalIncluirEtapasPPB') appModalIncluirEtapasPPB : any;

	constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private changeListagemProdutosService: ChangeListagemProdutosService
	) {
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

	ngOnInit() {
		this.listar(this.idPPB);
    this.changeListagemProdutosService.AtualizaListagemProdutos.subscribe(
			(obj: ServiceTipoListagemVM) => {
        if(obj != null){
          this.listar(obj.idPpb);
        }
      }
		);
	}

	ocultar() { }

	onChangeSort($event : any)
	{
		this.grid.sort = $event;
		this.listar(this.idPPB);
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
    this.grid.page = 1;
		this.listar(this.idPPB);
	}

	onChangePage($event : any) {
		this.grid.page = $event;
		this.listar(this.idPPB);
    }

	public listar(idPPB : number)
  {
		var objeto : any = {};
		objeto.idPpb = idPPB;
		objeto.page =  this.grid.page ? this.grid.page : 1;
		objeto.size = this.grid.size ? this.grid.size : 10;
		objeto.sort = null;
		objeto.reverse =  this.grid.sort.reverse;
		objeto.todosOsProdutos = this._fromVinculos;
    objeto.fromModalListagemPpb = this._fromModalListagemPpb;
    objeto.objetoConsultaFiltrada = this.objConsultaFiltradaModalListagem;
    objeto.isConsultaFiltradaModalListagem = this.isConsultaFiltradaModalListagem;

		this.applicationService.get(this.servico+"/PaginadoVinculos", objeto).subscribe( (retorno : PagedItems) => {
			if(retorno.total > 0) {
					this.grid.lista = retorno.items;
					this.grid.total = retorno.total;
			} else {
				this.grid = { sort: {} };			
			}
		});
	}


}
