import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalRecaptchaComponent } from './modal-recaptcha.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: [ModalRecaptchaComponent],
  exports: [ModalRecaptchaComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class ModalRecaptchaModule { }
