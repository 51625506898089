export const environment = {

	production: false,
	developmentMode: false,
    serviceUrl: "https://appmppbprj.des.suframa.gov.br/",
    captchaSiteKey: "6LcS10AUAAAAALMez324a5YeL8MEC9PVcDubeWvB",
    hcaptchaSiteKey: "d7f5572b-efd3-457f-933f-51366095eb5f",
    menuPssUrl: "https://appmppbprj.des.suframa.gov.br/Menu",
    pssUrl: "https://pss.hom.suframa.gov.br/cas/login?service=https://mppbprj.des.suframa.gov.br",
    mppbUrl: "https://appmppbprj.des.suframa.gov.br/sessao",
    logout: "https://appmppbprj.des.suframa.gov.br/logout",
    psslogout: "https://pss.hom.suframa.gov.br/backend-pss-autorizacao/rest/usuario/logout",
    prjGeral :  "https://appgeralprj.des.suframa.gov.br",
    identificadorModuloPRJ : 1, //MÓDULO MPPB
    permiteVisualizarGridInconsistencias : true
};
