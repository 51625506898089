import { NgModule, LOCALE_ID } from '@angular/core';

// Importação de módulos nativos devem ser organizados por ordem alfabética
import { AppRoutingModule } from '../route/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Importação de componentes customizados devem ser organizados por ordem alfabética
import { CalendarModule } from 'angular-calendar';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { PipesModule } from './pipes/pipes.module';
import { ServicesModule } from './services/services.module';
import { MockModule } from './mock/mock.module';

//importacao Modais de Interação
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ModalRecaptchaModule } from './components/modal/modal-recaptcha/modal-recaptcha.module';

@NgModule({
	// Imports devem ser organizados por ordem alfabética
	imports: [
		AppRoutingModule,
		BrowserModule,
		CalendarModule,
		CommonModule,
		ComponentsModule,
		DirectivesModule,
		FormsModule,
		HttpClientModule,
		InterceptorsModule,
		PipesModule,
		ServicesModule,
		MockModule,
		MatDialogModule,
		MatIconModule,
		MatButtonModule,
		ModalRecaptchaModule
		
	],
	exports: [
		CalendarModule,
		ComponentsModule,
		DirectivesModule,
		PipesModule
	],
	providers: [
		{
			provide: LOCALE_ID,
			useValue: navigator.language,
		}
	]
})
export class SharedModule { }
