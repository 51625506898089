<div
    #appModalEtapasCondicoesPPB
    class="modal modal-wide-2 fade in"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    style="display: auto; overflow: scroll;"
    id="modal-top-historico-listagens">
    <div class="modal-dialog">

        <div class="modal-content">

            <div class="modal-header bg-primary" style="padding: 10px 15px;">
                <h4 class="modal-title" style="color: White;" id="modalParecer">Listagens do PPB</h4>
            </div>

            <div class="modal-body">

              <div class="pull-right">

                <a
                *ngIf="!isUsuarioExterno"
                  style="margin-top: -100px; margin-right: 8px;"
                  class="btn btn-default btn-sm"
                  data-toggle="tooltip" (click)="abrirHistorico()" >
                    <i class="i i-history"></i> Histórico das Listagens
                </a>
                <a (click)="fechar()" class="btn btn-sm btn-default" style="margin-top: -100px;" data-dismiss="modal">
                    <i class="fa fa-arrow-left"></i> Voltar
                </a>
              </div>

              <div class="row form-group">
                  <div class="col-lg-12">
                    <div class="col-sm-6">
                      <label for="descricao-titulo-ppb">Descrição Documento:</label>
                      {{titulo}}
                    </div>
                    <div class="col-sm-6" *ngIf = " finalizouConsultasDropDown ">
                      <a
                        class="btn btn-default btn-sm pull-right"
                        data-toggle="tooltip" (click)="gerarRelatorioFiltrado()" >
                          <i class="fa fa-print"></i> Imprimir
                      </a>
                    </div>
                  </div>
              </div>

              <div class="row form-group">
                  <div class="col-lg-12"
                      style="border-width: 5px; border-style: solid; border-color: black;"
                      *ngIf="flagExibeCabecalhoRelatorio"
                  >
                    <div class="col-sm-12">
                      <app-cabecalho-relatorio-ppb
                        style="margin-top:10px;"
                        [(objetoExportacao)]="objetoCabecalho"
                        [_dataVigencia]="_dataVigencia"
                        [_dataPublicacao]="_dataPublicacao"
                        [_dataDocumento]="_dataDocumento"
                        [_isSempontuacao]="_isSempontuacao"
                        [_isPorPontuacao]="_isPorPontuacao"
                        [_isPorGrupo]="_isPorGrupo"
                        [_isPPBMultiplo]="_isPPBMultiplo"
                        [listaAnexos]="listaAnexos"
                        [arrayDocumentosVinculados]="arrayDocumentosVinculados"
                        [isDocPosterior]="isDocPosterior"
                        [arrayDocumentoPosteriores]="arrayDocumentoPosteriores"
                        [statusPPB]="objetoCabecalho.informacoesPPB.ppbVM.statusPPB">
                      </app-cabecalho-relatorio-ppb>
                    </div>
                  </div>
              </div>

              <!-- <div>
                <div class="row form-group">
                  <div class="col-lg-12">
                    <div class="col-sm-2">
                      is grupo: {{_isPorGrupo}}
                    </div>
                    <div class="col-sm-2">
                      is semPontuacao: {{_isSempontuacao}}
                    </div>
                    <div class="col-sm-2">
                      is porPontuacao: {{_isPorPontuacao}}
                    </div>
                    <div class="col-sm-2">
                      is multiplo: {{_isPPBMultiplo}}
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="row form-group">
                <div class="col-lg-12">
                  <div class="col-sm-12">
                    <label for="resumo-tecnico">Tipo Etapa:</label>
                      <input
                      *ngIf="!eTipoComReferencias()"
                      type="text"
                      [(ngModel)]="descricaoPrimeiraEtapa"
                      class="form-control"
                      disabled
                    >
                    <select
                      *ngIf="eTipoComReferencias()"
                      name="tipo-etapa"
                      id="tipo-etapa"
                      [(ngModel)]="etapaSelecionada"
                      (change)="alteraDropDownsTipoEtapa()"
                      class="form-control">
                      <option
                        *ngFor="let item of arrayDropDownEtapas"
                        [value]="item.id"
                        [selected]="item.id === etapaSelecionada">
                        {{item.descricao}}
                      </option>
                    </select>
                    <span
                      *ngIf="arrayDropDownEtapas.length > 1"
                      style="color: red;">
                      Ao Selecionar esse campo, ele irá somente alterar os registros dentro dos filtros. O mesmo não interfere nas listagens.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-lg-12">
                  <div class="col-sm-6">
                    <label for="resumo-tecnico">PPB Múltiplo:</label>
                        <select
                          *ngIf="habAreasFiltro.ppbMultiplo"
                          name="ppb-multiplo-modal-listagem"
                          id="ppb-multiplo-modal-listagem"
                          [(ngModel)]="objetoFiltro.idPpbMultiplo"
                          (change)="alteraDropDownPpbMultiplo()"
                          class="form-control">
                            <option
                                value="undefined"
                                disabled>
                                    Selecione uma opção ...
                            </option>
                            <option
                              *ngFor="let item of dropDowns.ppbMultiplo"
                              [value]="item.id"
                              [selected]="item.id === objetoFiltro.idPpbMultiplo">
                              {{item.descricao}}
                            </option>
                        </select>
                        <input
                        *ngIf="!habAreasFiltro.ppbMultiplo"
                          type="text"
                          class="form-control"
                          disabled
                        >
                  </div>

                  <div class="col-sm-6">
                    <label for="resumo-tecnico">Grupo:</label>
                    <select
                    *ngIf="habAreasFiltro.grupo"
                      name="grupo-modal-listagem"
                      id="grupo-modal-listagem"
                      [(ngModel)]="objetoFiltro.idGrupo"
                      (change)="alteraDropDownGrupo()"
                      class="form-control">
                        <option
                            value="undefined"
                            disabled>
                                Selecione uma opção ...
                        </option>
                        <option
                          *ngFor="let item of dropDowns.grupo"
                          [value]="item.id"
                          [selected]="item.id === objetoFiltro.idGrupo">
                          {{item.descricao}}
                        </option>
                    </select>
                    <input
                    *ngIf="!habAreasFiltro.grupo"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>


                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-12">

                  <div class="col-sm-6">
                    <label for="resumo-tecnico">Etapa:</label>
                    <select
                      *ngIf="habAreasFiltro.etapa"
                      name="etapa-modal-listagem"
                      id="etapa-modal-listagem"
                      [(ngModel)]="objetoFiltro.idEtapa"
                      (change)="selecionouEtapaOuCondicionate('Etapa');"
                      class="form-control">
                      <option
                          value="undefined"
                          disabled>
                              Selecione uma opção ...
                      </option>
                      <option
                        *ngFor="let item of dropDowns.etapas"
                        [value]="item.id"
                        >
                        {{item.descricao}}
                      </option>
                    </select>
                    <input
                    *ngIf="!habAreasFiltro.etapa"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-6">
                    <label for="resumo-tecnico">Condicionante:</label>
                        <select
                        *ngIf="habAreasFiltro.condicionante"
                          name="condicionante-modal-listagem"
                          id="condicionante-modal-listagem"
                          [(ngModel)]="objetoFiltro.idCondicionante"
                          (change)="selecionouEtapaOuCondicionate('Condicionante');"
                          class="form-control">
                            <option
                                value="undefined"
                                disabled>
                                    Selecione uma opção ...
                            </option>
                            <option
                              *ngFor="let item of dropDowns.condicionante"
                              [value]="item.id"
                              [selected]="item.id === objetoFiltro.idCondicionante">
                              {{item.descricao}}
                            </option>
                        </select>
                        <input
                        *ngIf="!habAreasFiltro.condicionante"
                          type="text"
                          class="form-control"
                          disabled
                        >
                  </div>

                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-12">
                  <div class="col-sm-6">
                    <label for="resumo-tecnico">Metas:</label>
                    <select
                      *ngIf="habAreasFiltro.meta"
                        name="metas-modal-listagem"
                        id="metas-modal-listagem"
                        [(ngModel)]="objetoFiltro.idMeta"
                        (change)="alteraDropDownMetas()"
                        class="form-control">
                          <option
                              value="undefined"
                              disabled>
                                  Selecione uma opção ...
                          </option>
                          <option
                            *ngFor="let item of dropDowns.metas"
                            [value]="item.id"
                            [selected]="item.id === objetoFiltro.idMeta">
                            {{item.descricao}}
                          </option>
                    </select>
                    <input
                    *ngIf="!habAreasFiltro.meta"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>

                  <div class="col-sm-6">
                    <label for="cnpj">Período da Vigência:</label>
                    <div class="input-group" style="display: flex;">
                        <input
                            autocomplete="off"
                            class="form-control date ng-pristine ng-valid ng-touched"
                            id="data-inicio-modal-listagens"
                            maxlength="10"
                            name="data-inicio-modal-listagens"
                            style="width: 48%;"
                            [(ngModel)]="objetoFiltro.dataInicio"
                            type="date"
                            ng-reflect-maxlength="10"
                            ng-reflect-name="dataInicio">
                                <span style="padding-right: 11px; padding-top: 8px; padding-left: 10px;">a</span>
                        <input
                            autocomplete="off"
                            class="form-control date ng-pristine ng-valid ng-touched"
                            id="data-fim-modal-listagens"
                            maxlength="10"
                            name="data-fim-modal-listagens"
                            style="width: 48%;"
                            [(ngModel)]="objetoFiltro.dataFim"
                            type="date"
                            ng-reflect-maxlength="10"
                            ng-reflect-name="dataFim">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-12">
                  <div class="col-sm-12">
                      <label for="codigo">Palavra-chave:</label>
                      <input
                        type="text"
                        [(ngModel)]="objetoFiltro.descricaoPrincipal"
                        name="palavra-chave-modal-listagem"
                        id="palavra-chave-modal-listagem"
                        class="form-control"
                      />
                      <div class="radio i-checks radio-sm" style="margin-right: 1px;">
                      <label class="input-sm">
                        <input
                          name="radio[custom]"
                          id="comeca-por-palavra-chave"
                          [(ngModel)]="objetoFiltro.tpPesquisaDescricao"
                          type="radio"
                          value="1">
                            <i></i> Começa por
                      </label>
                      <label class="input-sm">
                        <input
                          name="radio[custom]"
                          id="comeca-por-qualquer-parte"
                          [(ngModel)]="objetoFiltro.tpPesquisaDescricao"
                          type="radio"
                          value="2">
                            <i></i> Qualquer parte
                      </label>
                      <label class="input-sm">
                        <input
                          name="radio[custom]"
                          id="comeca-por-palavra-exata"
                          [(ngModel)]="objetoFiltro.tpPesquisaDescricao"
                          type="radio"
                          value="3">
                            <i></i> Palavra Exata
                      </label>
                      </div>
                  </div>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-lg-12" style="margin-left: 14px;">
                    <button
                      (click)="buscar()"
                      class="btn btn-sm btn-primary"
                      style="cursor:pointer">
                        <i class="fa fa-search"></i> Buscar
                    </button>
                    <button
                      (click)="limpar()"
                      class="btn btn-sm btn-default"
                      style="cursor:pointer; margin-left: 8px;">
                        <i class="fa fa-eraser"></i> Limpar
                    </button>
                </div>
              </div>

              <div>

                <app-ppb-multiplo
                  *ngIf=" finalizouConsultasDropDown && _isPPBMultiplo "
                  [(habilitarInputs)]="habilitarInputs"
                  [(idPPB)]="idPPB"
                  [(_fromTelaElaboracao)]="fromTelaElaboracao"
                  [isConsultaFiltradaModalListagem]="true"
                  [objConsultaFiltradaModalListagem]="objetoFiltro"
                  [_isModalVinculo]="_isModalVinculo">
                </app-ppb-multiplo>

                <app-grupos-ppb
                  *ngIf=" finalizouConsultasDropDown && _isPorGrupo "
                  [(habilitarInputs)]="habilitarInputs"
                  [(idPPB)]="idPPB"
                  [(buscarVinculos)]="buscarVinculos"
                  [(validacao)]="objetoValidacao"
                  [(_fromTelaElaboracao)]="fromTelaElaboracao"
                  [isConsultaFiltradaModalListagem]="true"
                  [objConsultaFiltradaModalListagem]="objetoFiltro"
                  [_isModalVinculo]="_isModalVinculo">
                </app-grupos-ppb>

                <app-etapas-ppb
                  *ngIf=" finalizouConsultasDropDown && flagExibeCabecalhoRelatorio "
                  [(_isSempontuacao)]="_isSempontuacao"

                  [(_isPorPontuacao)]="_isPorPontuacao"
                  [(_isPorGrupo)]="_isPorGrupo"
                  [(_isPPBMultiplo)]="_isPPBMultiplo"

                  [(habilitarInputs)]="habilitarInputs"
                  [(idPPB)]="idPPB"
                  [(buscarVinculos)]="buscarVinculos"
                  [(validacao)]="objetoValidacao"
                  [(_fromTelaElaboracao)]="fromTelaElaboracao"
                  [isConsultaFiltradaModalListagem]="true"
                  [(objConsultaFiltradaModalListagem)]="objetoFiltro"
                  [_isModalVinculo]="_isModalVinculo">
                </app-etapas-ppb>

                <app-condicionantes-ppb
                  *ngIf=" finalizouConsultasDropDown "
                  [(habilitarInputs)]="habilitarInputs"
                  [(idPPB)]="idPPB"
                  [(buscarVinculos)]="buscarVinculos"
                  [(validacao)]="objetoValidacao"
                  [(_fromTelaElaboracao)]="fromTelaElaboracao"
                  [isConsultaFiltradaModalListagem]="true"
                  [objConsultaFiltradaModalListagem]="objetoFiltro"
                  [_isModalVinculo]="_isModalVinculo">
                </app-condicionantes-ppb>

                <app-metas-ppb
                  *ngIf=" (finalizouConsultasDropDown && flagExibeCabecalhoRelatorio)
                          &&
                          (_isPorPontuacao || _isPorGrupo)
                        "
                  [(idPPB)]="idPPB"
                  [(_isPorPontuacao)]="_isPorPontuacao"
                  [(_isPorGrupo)]="_isPorGrupo"
                  [(buscarVinculos)]="buscarVinculos"
                  [(habilitarInputs)]="habilitarInputs"
                  [(validacao)]="objetoValidacao"
                  [(_fromTelaElaboracao)]="fromTelaElaboracao"
                  [isConsultaFiltradaModalListagem]="true"
                  [objConsultaFiltradaModalListagem]="objetoFiltro"
                  [_isModalVinculo]="_isModalVinculo">
                </app-metas-ppb>

                <app-produtos-vinculados
                  *ngIf="finalizouConsultasDropDown"
                  [_mostrarCampoDocumento]="true"
                  [(habilitarInputs)]="habilitarInputs"
                  [(idPPB)]="idPPB"
                  [_fromVinculos]="true"
                  [_fromModalListagemPpb]="true"
                  [isConsultaFiltradaModalListagem]="true"
                  [objConsultaFiltradaModalListagem]="objetoFiltro"
                  [_isModalVinculo]="_isModalVinculo">
                </app-produtos-vinculados>


                <div *ngIf="exibirGridPontMinima()">

                  <app-cronograma-pontuacao-minima-ppb
                  [habilitarInputs]="habilitarInputs"
                  [dataVigenciaInicialPPB]="_dataVigenciaParams"
                  [idPPB]="idPPB"
                  [_isPorPontuacao]="_isPorPontuacao"
                  [buscarVinculos]="buscarVinculos"
                  [validacao]="objetoValidacao"
                  [isAlteracaoParaRevogacao]="isAlteracaoParaRevogacao"
                  [isRetificacao]="isRetificacao"
                  [_fromTelaElaboracao]="fromTelaElaboracao"
                  *ngIf="finalizouConsultasDropDown"
                  [_fromModalListagemPpb]="true"
                  [_isModalVinculo]="_isModalVinculo">
                </app-cronograma-pontuacao-minima-ppb>
                </div>

              </div>

            </div>

            <div class="modal-footer" style="padding: 10px 15px;">
                <a (click)="fechar()" class="btn btn-sm btn-default" data-dismiss="modal">
                    <i class="fa fa-arrow-left"></i> Voltar
                </a>
            </div>

        </div>

    </div>
</div>
<div
  #appModalEtapasCondicoesPPBBackground
  class="modal-backdrop fade in"
  style="display: none;">
</div>

<app-modal-historico-listagens
  #appModalHistorico>
</app-modal-historico-listagens>

<app-relatorio-listagens-ppb
  #appRelatorioConsolidado
  *ngIf = "mostraComponenteRelatorio"
  [_tituloPPB] = " _tituloPPB"
  [_isSempontuacao] = "_isSempontuacao"
  [_isPorPontuacao] = "_isPorPontuacao"
  [_isPorGrupo] = "_isPorGrupo"
  [_isPPBMultiplo] = "_isPPBMultiplo"
  [arrayDocumentosVinculados] = "arrayDocumentosVinculados"
  [arrayDocumentoPosteriores] = "arrayDocumentoPosteriores"
  [listaAnexos] = "listaAnexos"
  [temArquivo] = "temArquivo"
  [_dataDocumento] = "_dataDocumento"
  [_dataPublicacao] = "_dataPublicacao"
  [_dataVigenciaParams] = "_dataVigencia"
  [_dataVigencia] = "_dataVigencia"
  [objetoExportacao] = "objetoRelatorioFiltrado"
  [flagAtivarTxtDocReferencia] = "flagAtivarTxtDocReferencia"
  [isDocPosterior] = "_isDocPosterior"
  (DownloadFinalizado) = "mostraComponenteRelatorio = false"
  [_idPPBRota] = "idPPB"
>
</app-relatorio-listagens-ppb>
