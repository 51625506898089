import { Component, EventEmitter, Injectable, OnInit, Output, Pipe, ViewChild, forwardRef } from "@angular/core";
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { PagedItems } from "../../../view-model/PagedItems";
import { MessagesService } from "../../../shared/services/messages.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from "../../../shared/services/validation.service";
import { ApplicationService } from "../../../shared/services/application.service";
import { ModalService } from '../../../shared/services/modal.service';
import { EnumRestResponse } from '../../../shared/enums/EnumRestResponse';
import { EnumStatusPPB } from '../../../shared/enums/EnumStatusPPB';
import { SolicitarCadastroPPBVM } from '../../../view-model/SolicitarCadastroPPBVM';
import { DocumentosVinculadosPPBVM } from '../../../view-model/DocumentosVinculadorPPBVM';
import { PPBVM } from '../../../view-model/PPBVM';
import moment = require("moment");
import * as $ from 'jquery';
import { NgLocalization } from "@angular/common";
import { ValidacaoMPPBVM } from "../../../view-model/ValidacaoMPPBVM";
import { ChangeEtapaPPBService } from '../../../shared/services/changeEtapaPPB.service';
import { ChangeCondicionantePPBService } from '../../../shared/services/changeCondicionantePPB.service';
import { ChangeGrupoPPBService } from '../../../shared/services/changeGrupoPPB.service';
import { ChangePpbMultiploService } from '../../../shared/services/changePpbMultiplo.service';
import { PPBAnexoVM } from "../../../view-model/PPBAnexoVM";
import { NULL_EXPR, THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { RelatorioListagensPPBVM } from "../../../view-model/RelatorioListagensPPBVM";
import { DocumentosPosterioresPPBVM } from "../../../view-model/DocumentoPosterioresPPBVM";
import { FinalizacaoPpbResponseVM } from "../../../view-model/FinalizacaoPpbResponseVM";
import { ServiceTipoListagemVM } from "../../../view-model/ServiceTipoListagemVM";
import { ControladorTiposEtapaPPB } from "../../../shared/services/controladorTiposEtapaPpb.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {PPBCronogramaVM} from "../../../view-model/PPBCronogramaVM";


enum EnumTipoEtapa {
	SEM_PONTUACAO = 1 ,
	POR_PONTUACAO = 2 ,
	POR_GRUPO = 3 ,
	PPB_MULTIPLO  = 4
}

enum EnumTipoPublicacao{
	FIXAÇÃO = 1,
	ALTERAÇÃO_PARA_REVOGAÇÃO = 2,
	ALTERAÇÃO_PARCIAL = 3,
	NORMA_COMPLEMENTAR = 4,
	FIXAÇÃO_DE_PPB_ESPECÍFICO = 5
}

@Component({
	selector: "app-controle-solicitacoes",
	templateUrl: "./controle-solicitacoes-ppb.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControleSolicitacoesComponent),
      multi: true
    }
  ]
})

@Injectable()
export class ControleSolicitacoesComponent implements OnInit, ControlValueAccessor {

	objetoExportacao : any = {};
    mostraComponenteRelatorio: boolean = false;
	flagAtivarTxtDocReferencia : boolean = false;
	flagAtivaTxtDocPosterior : boolean = false;
	flagIsDocumentoPrincipal : boolean = false;
	fromTelaElaboracao : boolean = true;


  exibeRevogados:boolean = true;

	/*****DATAS DO PPB*********************************/
	_dataDocumento : string;
	_dataPublicacao : string;
	_dataVigencia : string;
	_dataVigenciaParams : string; //data que sera passada para etapa e condicionates.
	/**************************************************/

	/***PROPRIEDADES REFERENTES AO CONTROLE DE ANEXO***/
	limiteArquivo = 10485760; // 10MB
	temArquivo : boolean = false;
	filetype: string;
	filesize: number;
	types = ['application/pdf'];
	listaAnexos = new Array<PPBAnexoVM>();
	/**************************************************/

	/*******************FLAGS GRID********************/
	_isSempontuacao : boolean = false;
	_isPorPontuacao : boolean = false;
	_isPorGrupo : boolean = false;
	exibirGridPontuacaoMinima : boolean = false;
	_isPPBMultiplo : boolean = false;

  habilitaBotoesDosGrupos : boolean = true;
  habilitaBotoesDosPpbsMultiplos : boolean = true;
  habilitaBotoesDasEtapas : boolean = true;
  habilitaBotoesDasMetas : boolean = true;
  habilitaBotoesDasCondicionantes : boolean = true;
  habInputDocumentoReferencia:boolean = true;
	/*************************************************/

	/*****************TIPO PUBLICAÇÃO*****************/
	FIXACAO : number = 1;
	ALTERACAO_REVOGACAO : number = 2;
	ALTERACAO_PARCIAL : number = 3;
	NORMA_COMPLEMENTAR : number= 4;
	/*************************************************/

	/***********ARRAY DE DOCUMENTOS VINCULADOS********/
	arrayDocumentosVinculados : Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();

	/***********ARRAY DE DOCUMENTOS POSTERIORES*******/
	arrayDocumentoPosteriores : Array<DocumentosVinculadosPPBVM> = new Array<DocumentosVinculadosPPBVM>();
	objetoValidacao : ValidacaoMPPBVM = new ValidacaoMPPBVM();

	/***************** "Alteração para Revogação" o botão de ação de EXCLUIR não será exibido ************************/
	isAlteracaoParaRevogacao : boolean;
	isRetificacao : boolean;

	form = this;
	path : string;
	grid: any = { sort: {} };
	ocultarFiltro: boolean = false;
	ocultarGrid: boolean = true;
	htmlContent = '';
	parametros : SolicitarCadastroPPBVM = new SolicitarCadastroPPBVM();
	habilitarInputs: boolean = true;
	idPPB : number;
	buscarVinculos: boolean = true;

	//********* FLAGS UTILIZADAS NA TRANSIÇÃO VISUAL DOS FORMULARIOS **********/
	exibirFormulario : boolean = true;
	usuarioAlterouFormulario : boolean = false;

	@Output() atualizarListagem : EventEmitter<any> = new EventEmitter();

	//********SERVICOS*********************************
	servicoCadastroPPB = 'SolicitarCadastroPPB';
	servicoCadastroPPBAnexo = 'AnexoPPB';
	servicoFinalizar = "FinalizarPPB";
	removerVinculoBackEnd = "RemoverVinculo";
  servicoPpb = "PPB"
  value: any;

	listaTipoDocumento : any = {};

	listaCronograma = new Array<PPBCronogramaVM>();

	@ViewChild('DocumentoReferencia') DocumentoReferencia : any;
	@ViewChild('DocumentoPosterior') DocumentoPosterior : any;
	@ViewChild('appModalEscolherOrgaos') appModalEscolherOrgaos : any;
	@ViewChild('appRelatorioConsolidado') appRelatorioConsolidado : any;
	@ViewChild('dataInicioVigencia') dataInicioVigencia;
	@ViewChild('dataDocumento') dataDocumento;
	@ViewChild('arquivo') arquivo;
	@ViewChild('titulo') titulo;
	@ViewChild('tipoDocumento') tipoDocumento;
	@ViewChild('radioSemPontuacao') radioSemPontuacao;
	@ViewChild('radioPorPontuacao') radioPorPontuacao;
	@ViewChild('radioPorGrupo')  radioPorGrupo;
	@ViewChild('appModalVinculoProdutos') appModalVinculoProdutos;
	@ViewChild('anexoPPB') anexoPPB;
	@ViewChild('appModalHistorico') appModalHistorico;

	constructor(
    private ChangeEtapaPPBService: ChangeEtapaPPBService,
    private ChangeCondicionantePPBService: ChangeCondicionantePPBService,
    private ChangeGrupoPPBService: ChangeGrupoPPBService,
    private ChangePpbMultiploService: ChangePpbMultiploService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private modal: ModalService,
    private msg: MessagesService,
    private validation: ValidationService,
    private router: Router,
    private controlTipoEtapaPPB:ControladorTiposEtapaPPB
	) {
		$("div.id_100 select").val("undefined"); //seleciona item padrão no combobox Tipo de Documento.
		this.path = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
	}

  writeValue(value: string): void {
    this.value = value ? value : ''
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

	ngOnInit() {
    registerLocaleData(es);

    registerLocaleData(es);

		this.preencheComboboxTipoDocumento();

		if(this.path != 'novo'){
			this.idPPB = Number(this.route.snapshot.params['idPpb']);
			this.buscar(this.idPPB);

			if(this.path == 'visualizar'){
				this.habilitarInputs = false;
				if(this.parametros.ppbVM.statusPPB != EnumStatusPPB.EM_ELABORACAO){
					this.buscarVinculos = false;
				}
			}
			if(this.path == 'validacao'){
				this.validacaoPPB(true);
			}
		}
	}

  async validacaoPPB(flagPermiteVoltar: boolean) {
    this.applicationService.get("Validar", this.idPPB).subscribe((response: ValidacaoMPPBVM) => {
      if (response._flagExistePendencia) {
        this.modal.error("PPB com dados Inconsistentes! <br> Informe os Seguintes Campos: <b> " + response.descricaoPendencia + " </b>", "Informação");
        this.objetoValidacao = response;
      } else {
        this.objetoValidacao = new ValidacaoMPPBVM();
        this.modal.sucesso("PPB Apto para Finalização!", "Sucesso").subscribe(() => {
          flagPermiteVoltar ?
            this.voltar() :
            this.router.navigate(['/solicitacoes-ppb/' + this.idPPB + '/editar']);
        });
      }
    });
  }

	onChangeSort($event : any) {
		this.grid.sort = $event;
	}

	onChangeSize($event : any) {
		this.grid.size = $event;
	}

	onChangePage($event : any) {
		this.grid.page = $event;
  }

  voltar(){
		this.router.navigate(['/manter-ppb']);
  }

	validar(){

		if(!this.parametros.ppbVM.tipoPublicacao){

			this.modal.informacao("Campo Obrigatório Não Informado: <b>Tipo Publicação</b>", "Atenção");
			return false;

		} else {

			if(	Number(this.parametros.ppbVM.tipoPublicacao) != EnumTipoPublicacao.FIXAÇÃO &&
					 ((this.flagAtivarTxtDocReferencia && !this.parametros.ppbVM.descricaoDocReferencia) || !this.flagAtivarTxtDocReferencia) &&
					  !this.arrayDocumentosVinculados.length
			   )
			{
				this.modal.informacao("Informe um <b>documento de referencia</b> ou um <b>Documento de referência antigo</b>", "Atenção");
				return false;
			}

			if(Number(this.parametros.ppbVM.tipoPublicacao) == EnumTipoPublicacao.FIXAÇÃO_DE_PPB_ESPECÍFICO)
			{
				if(!this.arrayDocumentosVinculados.length && !this.parametros.ppbVM.descricaoDocReferencia){
					this.modal.informacao("Informe um <b>documento de referencia</b> ou um <b>Documento de referência antigo</b>", "Atenção");
					return false;
				}
				if(this.arrayDocumentosVinculados && this.arrayDocumentosVinculados.length > 1){
					this.modal.informacao("O PPB deve possuir apenas um documento de referencia", "Atenção");
					return false;
				}
			}

			this.parametros.ppbVM.tipoPublicacao = Number(this.parametros.ppbVM.tipoPublicacao);
		}

		if(
			Number(this.parametros.ppbVM.tipoPublicacao) != EnumTipoPublicacao.ALTERAÇÃO_PARA_REVOGAÇÃO ||
			(this.arrayDocumentosVinculados && this.arrayDocumentosVinculados.length > 1)
		  )
		{
			this.parametros.flagIsDocumentoPrincipal = false;
		}

		if(this.parametros.idPPBDocumentoSelecionado){
			this.parametros.idPPBDocumentoSelecionado = Number(this.parametros.idPPBDocumentoSelecionado);
		}

		if(this.flagAtivarTxtDocReferencia && !this.parametros.ppbVM.descricaoDocReferencia){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Documento de Referência (Cadastro Manual)</b>", "Atenção");
			return false;
		}

		if(!this.parametros.dataPublicacaoString){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Data de Publicação</b>", "Atenção");
			return false;
		}

		if(!this.parametros.ppbVM.idTipoDocumento){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Tipo de Documento</b>", "Atenção");
			return false;
		}

		if(!this.parametros.ppbVM.numeroDocumento || this.parametros.ppbVM.numeroDocumento == null || this.parametros.ppbVM.numeroDocumento == undefined ||  this.parametros.ppbVM.numeroDocumento == 0){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Número</b>", "Atenção")
			return false;
		} else{

      if(this.parametros.ppbVM.numeroDocumento.toString().indexOf(".") != -1){
        this.modal.informacao("O Número do PPB não pode conter Vírgula <b>','</b>.", "Atenção")
			  return false;
      }else{
			  this.parametros.ppbVM.numeroDocumento = Number(this.parametros.ppbVM.numeroDocumento);
      }
		}

		if(!this.parametros.dataDocumentoString){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Data Documento</b>", "Atenção");
			return false;
		}

		if(!this.parametros.ppbVM.descricaResumoTecnico){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Resumo Técnico</b>", "Atenção");
			return false;
		}

		if(!this.parametros.dataInicioVigenciaString){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Início de Vigência</b>", "Atenção");
			return false;
		}

		var descricaoTitulo = document.getElementById('descricao-titulo').innerHTML;
		if(descricaoTitulo.length){
			this.parametros.ppbVM.descricaoTitulo = descricaoTitulo.trim();
      this.parametros.ppbVM.descricaoTitulo =	this.parametros.ppbVM.descricaoTitulo.trim();
		}

		if(this.parametros.ppbVM.tipoPublicacao == EnumTipoPublicacao.FIXAÇÃO_DE_PPB_ESPECÍFICO && this.flagAtivarTxtDocReferencia && this.arrayDocumentosVinculados.length > 0){
			this.modal.informacao("Não é possível selecionar um documento de referencia atual e um antigo, escolha apenas um.");
			return false;
		}

		if(this.parametros.ppbVM.tipoPublicacao == EnumTipoPublicacao.FIXAÇÃO_DE_PPB_ESPECÍFICO && !this.flagAtivarTxtDocReferencia){
			this.parametros.ppbVM.descricaoDocReferencia = null;
		}

		if(!this.flagAtivarTxtDocReferencia){
			this.parametros.ppbVM.descricaoDocReferencia = "";
		}

		if(this.listaAnexos == null || this.listaAnexos == undefined || this.listaAnexos.length == undefined || this.listaAnexos.length == 0){
			this.modal.informacao("Campo Obrigatório Não Informado: <b>Anexo</b>", "Atenção");
			return false;
		} else{
			this.parametros.ppbAnexoVM = this.listaAnexos;
		}

    if (this.parametros.ppbVM.tipoPublicacao != EnumTipoPublicacao.ALTERAÇÃO_PARCIAL) {
      if(!this.parametros.ppbVM.tipoEtapa){
        this.modal.informacao("Campo Obrigatório Não Informado: <b>Tipo Etapa</b>", "Atenção");
        return false;
      } else {
        this.parametros.ppbVM.tipoEtapa = Number(this.parametros.ppbVM.tipoEtapa);
      }
    }

    if(this.parametros.ppbVM.tipoPublicacao === EnumTipoPublicacao.ALTERAÇÃO_PARCIAL) {

      //se for um novo ppb, e o usuario anexou mais de 1 documento...
      if(!this.parametros.ppbVM.id && this.arrayDocumentosVinculados.length > 1){
        this.modal.informacao("Publicações do tipo <b>ALTERAÇÃO PARCIAL</b>, so podem conter <b>UM (1) Documento de Referência</b>.", "Atenção");
        return false;
      }

      this.parametros.ppbVM.tipoEtapa = null;
    }

		this.parametros.documentosVinculados = this.arrayDocumentosVinculados;
		this.parametros.documentosPosteriores = this.arrayDocumentoPosteriores;
    this.parametros.ppbVM.descricaoTitulo =  this.parametros.ppbVM.descricaoTitulo.trim();

		this.salvar();
	}

	buscar(idPPB : number){

		var objeto : PPBVM = new PPBVM();
		objeto.id = idPPB;

		this.applicationService.get(this.servicoCadastroPPB, objeto).subscribe((retorno : SolicitarCadastroPPBVM) => {
			if (retorno) {

				this.parametros = retorno;
				this.listaAnexos = retorno.ppbAnexoVM;
				this.listaCronograma = retorno.ppbCronogramaVM;

				retorno.ppbAnexoVM.length > 0 ? this.temArquivo = true : this.temArquivo = false;

				if (this.parametros.dataDocumentoString)
					this._dataDocumento = this.parametros.dataDocumentoString;

				if (this.parametros.dataPublicacaoString)
					this._dataPublicacao = this.parametros.dataPublicacaoString;

				if (this.parametros.dataInicioVigenciaString) {
					this._dataVigencia = this.parametros.dataInicioVigenciaString;
					this._dataVigenciaParams = this.parametros.dataInicioVigenciaString;
				}

				(this.parametros.ppbVM.tipoPublicacao == EnumTipoPublicacao.ALTERAÇÃO_PARA_REVOGAÇÃO) ?
					this.isAlteracaoParaRevogacao = true : this.isAlteracaoParaRevogacao = false;

        if(this.parametros.ppbVM.tipoEtapa) {
          this.parametros.ppbVM.tipoEtapa = String(this.parametros.ppbVM.tipoEtapa);
        }

        let porPontuacao = 2
        let porGrupoComPontuacao = 3
        if (this.parametros.ppbVM.tipoEtapa == porPontuacao ||
          this.parametros.ppbVM.tipoEtapa == porGrupoComPontuacao){
            this.exibirGridPontuacaoMinima = true
          }else{
          this.exibirGridPontuacaoMinima = false
        }



        // PUBLICAÇÕES DO TIPO ALTERACAO PARCIAL, TERAO SEUS GRIDS MONTADOS CONFORME AS REFERENCIAS.
        const tipoPublicacao = this.parametros.ppbVM.tipoPublicacao;
        const tipoEtapa = this.parametros.ppbVM.tipoEtapa;
        const tiposPublicacaoReferencia = retorno.tipoPublicacoesDasReferencias;

        // PUBLICAÇÕES DO TIPO ALTERACAO PARCIAL E ALTERACAO PARA REVOGAÇÃO,
        // TERAO SEUS GRIDS MONTADOS CONFORME AS REFERENCIAS. A MESCLAGEM DE ALTERAÇÃO
        // PARA REVOGAÇÃO, TERÃO SEUS BOTÕES DESABILITADOS.

        if (
            tipoPublicacao == EnumTipoPublicacao.ALTERAÇÃO_PARCIAL ||
            tipoPublicacao == EnumTipoPublicacao.ALTERAÇÃO_PARA_REVOGAÇÃO
          ) {
            const tiposEtapaFormulado = this.controlTipoEtapaPPB.definirTiposEtapa(tipoPublicacao,tipoEtapa,tiposPublicacaoReferencia);

            this._isPPBMultiplo = tiposEtapaFormulado.isMultiplo;
            this._isPorGrupo = tiposEtapaFormulado.isPorGrupo;
            this._isPorPontuacao = tiposEtapaFormulado.isPorPontuacao;
            this._isSempontuacao = tiposEtapaFormulado.isSemPontuacao;


            this.habilitaBotoesDosPpbsMultiplos = this._isPPBMultiplo;
            this.habilitaBotoesDosGrupos = this._isPorGrupo
            this.habilitaBotoesDasMetas = (this._isPorGrupo || this._isPorPontuacao);

        }
        else {
					this.validaTipoEtapa(Number(tipoEtapa));
        }

        if (this.parametros.documentosVinculados)
					this.arrayDocumentosVinculados = this.parametros.documentosVinculados;

				if (this.parametros.documentosPosteriores && this.parametros.documentosPosteriores.length) {
					this.arrayDocumentoPosteriores = this.parametros.documentosPosteriores;
					this.flagAtivaTxtDocPosterior = true;
				}

				if (this.parametros.ppbVM.descricaoDocReferencia)
					this.flagAtivarTxtDocReferencia = true;

				(this.parametros.ppbVM.statusPPB == EnumStatusPPB.EM_RETIFICACAO) ?
				this.isRetificacao = true : this.isRetificacao = false;

				this.preencheComboboxTipoDocumento();
			}
		});
	}

	buscarDadosExportacao()
	{
		this.applicationService.get("RelatorioListagensPPB", this.idPPB).subscribe((result : RelatorioListagensPPBVM) => {

			this.objetoExportacao = result;

			this.listaAnexos = result.informacoesPPB.ppbAnexoVM;

			result.informacoesPPB.ppbAnexoVM.length > 0 ?
				this.temArquivo = true :
					this.temArquivo = false;

			if(this.objetoExportacao.informacoesPPB.dataDocumentoString)
				this._dataDocumento = this.objetoExportacao.informacoesPPB.dataDocumentoString;

			if(this.objetoExportacao.informacoesPPB.dataPublicacaoString)
				this._dataPublicacao = this.objetoExportacao.informacoesPPB.dataPublicacaoString;

			if(this.objetoExportacao.informacoesPPB.dataInicioVigenciaString) {
				this._dataVigencia = this.objetoExportacao.informacoesPPB.dataInicioVigenciaString;
				this._dataVigenciaParams = this.objetoExportacao.informacoesPPB.dataInicioVigenciaString;
			}

			if(result.informacoesPPB.ppbVM.tipoEtapa > 0){
				this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa = String(this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa);
				this.validaTipoEtapa(Number(this.objetoExportacao.informacoesPPB.ppbVM.tipoEtapa));
			}

			if(this.objetoExportacao.informacoesPPB.documentosVinculados)
				this.arrayDocumentosVinculados = this.parametros.documentosVinculados;

			if(this.objetoExportacao.informacoesPPB.ppbVM.descricaoDocReferencia)
				this.flagAtivarTxtDocReferencia = true;

			this.appRelatorioConsolidado.geraRelatorioConsolidado();
		});
	}

	salvar(){

		this.applicationService.post(this.servicoCadastroPPB, this.parametros).subscribe((idPPb : number) => {
			if(idPPb == 0){
				this.modal.infoError("Este PPB já está Cadastrado!", "Atenção");
				return false;
			}
      if(idPPb == -1){
        this.modal.error("As datas do Documento Precisam Obrigatóriamente seguir a seguinte Ordem Lógica: Data Documento <b> <= </b> Data da Publicação <b> <=  </b> Data de Inicio da Vigência", "Atenção");
				return false;
      }
			else if (idPPb > 0) {
				if(this.path == 'novo'){
					this.usuarioAlterouFormulario = false;
					this.modal.sucesso(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(()=>this.router.navigate(['solicitacoes-ppb/'+idPPb+'/editar']));
				} else {
					this.usuarioAlterouFormulario = false;
					this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success").subscribe(()=>this.buscar(idPPb));

					//atualiza grids de etapa, condicionante e grupo.
          const obj = new ServiceTipoListagemVM();
          obj.idPpb = this.idPPB;
          obj.getFromSessionStorage = false;

					this.ChangeEtapaPPBService.atualizaListagemPPB(obj);
					this.ChangeCondicionantePPBService.atualizaListagemPPB(obj);
					this.ChangeGrupoPPBService.atualizaListagemPPB(obj);
          this.ChangePpbMultiploService.atualizaListagemPPB(obj);
				}
			} else {
				this.modal.infoError("Falha ao Executar Operação..", "ERRO 500");
				return false;
			}

			this.objetoValidacao = new ValidacaoMPPBVM();
		});
	}

	onFileChange(event) {
		let reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
			let file = event.target.files[0];
			reader.readAsDataURL(file);
			this.filetype = file.type;
			this.filesize = file.size;
			if(this.types.includes(this.filetype)) {
				if(this.filesize < this.limiteArquivo){
					if(file.name.length < 150){
						this.temArquivo = true;
						reader.onload = () => {

							if(this.buscaAnexoPorDescricao(file.name)){

								var anexo = new PPBAnexoVM();
								anexo.descricaoArquivo = file.name;
								anexo.objetoArquivo = (reader.result as string).split(',')[1];
								this.listaAnexos.push(anexo);
								this.limparAnexoPdf();

							}else{
								this.modal.infoError("Esse Anexo já esta Vinculado!",'Atenção');
								this.limparAnexoPdf();
							}

						};
					} else {
						this.modal.infoError('O título do Anexo não pode conter mais de 150 Caracteres!','Atenção');
						this.limparAnexoPdf();
					}
				} else {
					this.modal.infoError(this.msg.ANEXO_ACIMA_DO_LIMITE.replace('($)','10'),'Atenção');
					this.limparAnexoPdf();
				}
			} else {
				this.modal.infoError(this.msg.FAVOR_SELECIONAR_UM_ARQUIVO_NO_FORMATO_PDF,'Atenção');
				this.limparAnexoPdf();
			}
		}else{
			this.temArquivo = false;
		}
	}

	buscaAnexoPorDescricao(descricao: string) {
		if(this.listaAnexos == null || this.listaAnexos == undefined || this.listaAnexos.length == undefined || this.listaAnexos.length == 0){
			this.listaAnexos = new Array<PPBAnexoVM>();
			return true;
		} else{
			var result = this.listaAnexos.find(anexo => anexo.descricaoArquivo == descricao);
			return result == undefined;
		}
	}

	validaTipoEtapa(tipoEtapa : number) {

    this._isSempontuacao = false;
    this._isPorPontuacao = false;
    this._isPorGrupo = false;
    this._isPPBMultiplo = false;

    this.habilitaBotoesDosGrupos = true;
    this.habilitaBotoesDosPpbsMultiplos = true;
    this.habilitaBotoesDasEtapas = true;
    this.habilitaBotoesDasMetas = true;
    this.habilitaBotoesDasCondicionantes = true;

		switch(tipoEtapa){
			case EnumTipoEtapa.SEM_PONTUACAO :
				this._isSempontuacao = true;
				break;
			case EnumTipoEtapa.POR_PONTUACAO :
				this._isPorPontuacao = true;
				break;
			case EnumTipoEtapa.POR_GRUPO :
				this._isPorGrupo = true;
				break;
			case EnumTipoEtapa.PPB_MULTIPLO :
				this._isPPBMultiplo = true;
				break;
		}
	}

	limparAnexoPdf() {
		this.temArquivo = false;
		this.anexoPPB.nativeElement.value = null;
	}

	buscarAnexoParaDownload(item: PPBAnexoVM){
		if(item.idAnexo == undefined){
			this.baixarAnexo(item);
		}else{
			this.applicationService.get(this.servicoCadastroPPBAnexo+"/Buscar", {id: item.idAnexo}).subscribe((retorno : PPBAnexoVM) => {
				if(retorno.objetoArquivo != null){
					this.baixarAnexo(retorno);
				} else {
					this.modal.infoError("Não foi possível baixar o anexo - ERRO 500.", 'Informação');
					return false;
				}
			});
		}
	}

	baixarAnexo(anexo: PPBAnexoVM) {
		if (anexo.objetoArquivo != null) {
			const hashPDF = anexo.objetoArquivo;
			const linkSource = 'data:' + 'application/pdf' + ';base64,' + hashPDF;
			const downloadLink = document.createElement('a');
			const fileName = anexo.descricaoArquivo;
			document.body.appendChild(downloadLink);
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.target = '_self';
			downloadLink.click();
		} else {
			this.modal.infoError('Erro ao baixar arquivo.', 'Informação');
			return false;
		}
 	}

	removerAnexoDaLista(item: PPBAnexoVM){
		var index = this.listaAnexos.indexOf(item);
		if(index > -1){
			this.listaAnexos.splice(index, 1);
		}
	}

	confirmarExclusao(item: PPBAnexoVM){
		this.modal.confirmacao("Deseja Excluir o Anexo?").subscribe((isConfirmado : any) => {
			 if(isConfirmado) {
				if(item.idPPB == undefined){
					this.removerAnexoDaLista(item);
				} else {
					this.excluirAnexoBackEnd(item);
				}
			 }
		});
	}

	excluirAnexoBackEnd(item: PPBAnexoVM){
		this.applicationService.delete(this.servicoCadastroPPBAnexo, item.idAnexo).subscribe((retorno : EnumRestResponse) => {
			if(retorno == EnumRestResponse.OK){
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");
				this.removerAnexoDaLista(item);
			} else {
				this.modal.infoError("Não foi Possível Excluir Anexo - ERRO 500.", this.msg.ERRO_500);
				return false;
			}
		});
	}

	abrirModalEscolhaOrgaos(){
		this.appModalEscolherOrgaos.abrir();
	}

	changeOrgaosPPB($event){
		this.parametros.ppbVM.descricaOrgao = $event;
	}

	selectTipoDocumento($event){
		var indiceSelecionado = this.tipoDocumento.nativeElement.options.selectedIndex;
		if(indiceSelecionado >= 0)
			this.parametros.descricaoTipoDocumento =  this.tipoDocumento.nativeElement.options[indiceSelecionado].label;
	}

	preencheComboboxTipoDocumento(){
		this.applicationService.get("DocumentoMppb").subscribe((retorno : any) => {
			if (retorno != null) {
				this.listaTipoDocumento = retorno;
			}
		});
		//if(this.path == 'novo'){

		// }else{
		// 	this.applicationService.get("DocumentoMppb", {id: this.parametros.ppbVM.idTipoDocumento}).subscribe((retorno : any) => {
		// 		if (retorno != null) {
		// 			this.listaTipoDocumento = retorno;
		// 		}
		// 	});
		// }

	}

	transformDataToStringFormat(data : Date){
		return this.validation.getDateWhithChangeFormat('YYYY-MM-DD' , data);
	}

	mostraDataTitulo(data : string){
		//return this.validation.getDateWhithChangeFormat('DD/MM/YYYY' , new Date(data), true);
		return new Date(data).getUTCDate() +" DE "+ this.transformaMes(new Date(data).getUTCMonth()+1) +" DE "+ new Date(data).getFullYear()
	}

	transformDataDocumento() {
		this.parametros.dataDocumentoString = this._dataDocumento;
		this.mostraDataTitulo(this.parametros.dataDocumentoString);
	}

	transformDataPublicacao(){
		this.parametros.dataPublicacaoString = this._dataPublicacao;
	}

	transformDataVigencia(){
		this.parametros.dataInicioVigenciaString = this._dataVigencia;
	}

	transformaMes(mes: number): string{
		switch (mes) {
			case 1:

				return 'JANEIRO';

			case 2:
				return 'FEVEREIRO';

			case 3:
				return 'MARÇO';

			case 4:
				return 'ABRIL';

			case 5:
				return 'MAIO';

			case 6:
				return 'JUNHO';

			case 7:
				return 'JULHO';

			case 8:
				return 'AGOSTO';

			case 9:
				return 'SETEMBRO';

			case 10:
				return 'OUTUBRO';

			case 11:
				return 'NOVEMBRO';

			case 12:
				return 'DEZEMBRO';
		}
	}

	validaVirgularTipoDocumento(){
		if(!this.parametros.ppbVM.descricaOrgao && !this.parametros.ppbVM.numeroDocumento && !this._dataDocumento){
			return this.parametros.descricaoTipoDocumento;
		} else {
			return this.parametros.descricaoTipoDocumento + ",";
		}
	}

	validaVirgularDescricaOrgao(){
		if(!this.parametros.ppbVM.numeroDocumento && !this._dataDocumento){
			return this.parametros.ppbVM.descricaOrgao;
		} else {
			return this.parametros.ppbVM.descricaOrgao + ",";
		}
	}

	validaVirgularNumeroDocumento(){
		if(!this._dataDocumento){
			return this.parametros.ppbVM.numeroDocumento;
		} else {
			return this.parametros.ppbVM.numeroDocumento + ",";
		}
	}

	limparTipoDocumento(){
		$("div.id_100 select").val("undefined");
		this.parametros.ppbVM.idTipoDocumento = null;
		this.parametros.descricaoTipoDocumento = null;
	}

	limparDataDocumento(){
		this.parametros.dataDocumentoString = null;
		this._dataDocumento = null;
	}

    exportarPDF(){
		this.mostraComponenteRelatorio = true;
		this.buscarDadosExportacao();
	}

	BaixouPdf($event){
		this.mostraComponenteRelatorio = $event;
		this.objetoExportacao = null;
	}

  async abrirFinalizar(item: PPBVM) {

    this.applicationService.get("Validar", this.idPPB).subscribe((response: ValidacaoMPPBVM) => {
      if (response._flagExistePendencia) {

        this.modal.error("PPB com dados Inconsistentes! <br> Informe os Seguintes Campos: <b> " + response.descricaoPendencia + " </b>", "Informação");
        this.objetoValidacao = response;

      }
      else
      {
        let fromRetificacao: boolean = false;

        if (item.statusPPB == EnumStatusPPB.EM_RETIFICACAO)
          fromRetificacao = true;

        this.applicationService.get(this.servicoFinalizar, { id: item.id }).subscribe((responseFinalizacao: FinalizacaoPpbResponseVM) => {
          if (responseFinalizacao.statusResponse == 200) {
            this.modal.sucesso("Operação realizada com sucesso", "Sucesso").subscribe(() => {
              if (responseFinalizacao.existePPBRevogado) {
                    this.applicationService
                      .post("FinalizacaoPPBComDesvinculamentoDeProdutos", responseFinalizacao)
                      .subscribe((responseDesvinculo: FinalizacaoPpbResponseVM) => {
                          if (responseDesvinculo.statusResponse == 200)
                          {
                            this.modal.sucesso("Os Produtos dos ppbs revogados foram migrados para o ppb corrente", "Atenção").subscribe(()=>{
                              this.appModalVinculoProdutos.abrir(item, true, fromRetificacao, true);
                            });
                          }
                          else
                          {
                            this.modal.error(responseDesvinculo.textResponse, "Erro 500").subscribe(x => {
                              this.voltar();
                            });
                          }
                    });
              }
              else {
                this.appModalVinculoProdutos.abrir(item, true, fromRetificacao, true);
              }
            });
          }
          else if (responseFinalizacao.statusResponse == 500) {
            this.modal.error(responseFinalizacao.textResponse, "Erro 500");
            return false;
          }
          else {
            this.modal.infoError("Falha ao finalizar ppb. Favor entrar em contato com a equipe técnica.", "Erro desconhecido");
          }
        });
      }
    });
  }

	async validaPpb(){
		await this.validacaoPPB(false);
	}

	changeListagem($event){
		this.voltar();
	}

	preencheArrayDocReferencia($event){
		if(typeof $event === "number"){

			let novoDocumento : DocumentosVinculadosPPBVM = new DocumentosVinculadosPPBVM();
			novoDocumento.idDocumentoSelecionado = $event;
			novoDocumento.descricaoDocumentoVinculado = this.DocumentoReferencia.model.text;//.split(" | ")[1].replace(" ", "");;

			if(this.parametros.ppbVM.tipoPublicacao == 5){
				if(this.arrayDocumentosVinculados && this.arrayDocumentosVinculados.length == 1){
					return false;
				}
			}

			if(this.arrayDocumentosVinculados){

				var itemJaVinculado = this.arrayDocumentosVinculados.filter(o => o.idDocumentoSelecionado == novoDocumento.idDocumentoSelecionado);

				if(itemJaVinculado.length == 0){

					this.arrayDocumentosVinculados = [...this.arrayDocumentosVinculados, novoDocumento]; //Append
          if(
            this.parametros.ppbVM.tipoPublicacao == 3
            ){
            this.habInputDocumentoReferencia = false;
            this.applicationService.get(
              "ppb/"+novoDocumento.idDocumentoSelecionado
              ).subscribe(
                (response:any)=>{
                  this.parametros.ppbVM.tipoEtapa = response.tipoEtapa+"";

                }
              )
          }
				}
			} else{
				this.arrayDocumentosVinculados = [...this.arrayDocumentosVinculados, novoDocumento]; //Append
			}
		}
	}

	excluirDocumento(index){
		this.modal.confirmacao("Deseja Excluir o Documento de Referencia?").subscribe((isConfirmado : any) => {
			if(isConfirmado) {
				if(this.arrayDocumentosVinculados[index].idVinculo > 0){
					let idVinculo = this.arrayDocumentosVinculados[index].idVinculo;
					this.apagaVinculoBackEnd(idVinculo);
				}
				this.arrayDocumentosVinculados.splice(index, 1);
        if(
          this.parametros.ppbVM.tipoPublicacao == 3
          ){
          this.habInputDocumentoReferencia = true;
          this.parametros.ppbVM.tipoEtapa = null;

        }
			}
	   });
	}

	apagaVinculoBackEnd(idVinculo : number) : any {
		this.applicationService.delete(this.removerVinculoBackEnd, idVinculo).subscribe((response: any) => {
			if(response == EnumRestResponse.OK){
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");
				this.buscar(this.idPPB);

        const obj = new ServiceTipoListagemVM();
        obj.idPpb = this.idPPB;
        obj.getFromSessionStorage = false;

				this.ChangeEtapaPPBService.atualizaListagemPPB(obj);
				this.ChangeCondicionantePPBService.atualizaListagemPPB(obj);
				this.ChangeGrupoPPBService.atualizaListagemPPB(obj);
				return true;
			} else {
				this.modal.infoError("Não foi Possível Excluir Anexo - ERRO 500.", this.msg.ERRO_500);
				return false;
			}
		});
	}

	preencheArrayDocPosterior($event){
		if(typeof $event === "number"){

			let novoDocumento : DocumentosVinculadosPPBVM = new DocumentosVinculadosPPBVM();
			novoDocumento.idDocumentoSelecionado = $event;
			novoDocumento.descricaoDocumentoVinculado = this.DocumentoPosterior.model.text;//.split(" | ")[1].replace(" ", "");;

			if(this.parametros.ppbVM.tipoPublicacao == 5){
				if(this.arrayDocumentoPosteriores && this.arrayDocumentoPosteriores.length == 1){
					return false;
				}
			}

			if(this.arrayDocumentoPosteriores){

				var itemJaVinculado = this.arrayDocumentoPosteriores.filter(o => o.idDocumentoSelecionado == novoDocumento.idDocumentoSelecionado);

				if(itemJaVinculado.length == 0){
					this.arrayDocumentoPosteriores = [...this.arrayDocumentoPosteriores, novoDocumento]; //Append
				}
			} else{
				this.arrayDocumentoPosteriores = [...this.arrayDocumentoPosteriores, novoDocumento]; //Append
			}
		}
	}

	excluirDocumentoPosterior(index){
		this.modal.confirmacao("Deseja Excluir o Documento de Referencia?").subscribe((isConfirmado : any) => {
			if(isConfirmado) {
				if(this.arrayDocumentoPosteriores[index].idVinculo > 0){
					let idVinculo = this.arrayDocumentoPosteriores[index].idVinculo;
					this.apagaVinculoBackEndDocPosterior(idVinculo);
				}
				this.arrayDocumentoPosteriores.splice(index, 1);
			}
	   });
	}

	apagaVinculoBackEndDocPosterior(idVinculo : number) : any {
		this.applicationService.delete(this.removerVinculoBackEnd, idVinculo).subscribe((response: any) => {
			if(response == EnumRestResponse.OK){
				this.modal.infoSucess(this.msg.OPERACAO_REALIZADA_COM_SUCESSO, "Success");
				this.buscar(this.idPPB);
				return true;
			} else {
				this.modal.infoError("Não foi Possível Excluir Anexo - ERRO 500.", this.msg.ERRO_500);
				return false;
			}
		});
	}

	validarCampoOrgao(event){
		var tecla = event.keyCode;
		if (tecla >= 48 && tecla <= 57){
			return false;
		}else{
		return true;
		}
	}

	changeMudarFormularios(){

		if(this.exibirFormulario && this.usuarioAlterouFormulario){
			this.modal.infoError("Houve uma alteração no Formulário! Clique em <b>Salvar</b> o para poder Visualizar as Listagens!", "Atenção");
			return false;
		}

		this.exibirFormulario ?
			this.exibirFormulario = false :
				this.exibirFormulario = true;

		this.usuarioAlterouFormulario = false;
	}

  changeDocumentoPosterior(){
    this.usuarioAlterouFormulario = true;
    if(!this.parametros.flagIsDocumentoPosterior){
      this.modal.confirmacao("O Documento corrente possui PPB posterior?").subscribe((isConfirmacao : any) => {
          if(isConfirmacao){
            this.parametros.flagIsDocumentoPosterior = true;
          }
          else
          {
            this.parametros.flagIsDocumentoPosterior = false;
          }
      });
    }
    else
    {
      this.parametros.flagIsDocumentoPosterior = false;
    }
  }

  changeDocumentoDeReferenciaAntigo(){
    this.usuarioAlterouFormulario = true;
    if(!this.flagAtivarTxtDocReferencia){
      this.modal.confirmacao("O Documento corrente possui documento de referência antigo?").subscribe((isConfirmacao : any) => {
          if(isConfirmacao){
            this.flagAtivarTxtDocReferencia = true;
          }
          else
          {
            this.flagAtivarTxtDocReferencia = false;
          }
      });
    }
    else
    {
      this.flagAtivarTxtDocReferencia = false;
    }
  }

  abrirHistorico() {
	  this.appModalHistorico.abrir(this.idPPB);
  }

}
