import { ChangeDetectorRef, Component, ElementRef, Injectable, NgZone, OnInit, ViewChild } from "@angular/core";
import { PagedItems } from "../../view-model/PagedItems";
import { ModalService } from '../../shared/services/modal.service';
import { MessagesService } from "../../shared/services/messages.service";
import { ValidationService } from "../../shared/services/validation.service";
import { ApplicationService } from "../../shared/services/application.service";
import { Router, ActivatedRoute } from '@angular/router';
import { ConsultarPPBVM } from "../../view-model/ConsultarPPBVM";
import { EnumStatusPPB } from "../../shared/enums/EnumStatusPPB";
import { PPBVM } from "../../view-model/PPBVM";
import { ChangeIniciaPaginacao } from "../../shared/services/changeIniciaPaginacao.service";
import { EnumPerfil } from "../../shared/enums/EnumPerfil";
import { EnumTipoEtapa } from "../../shared/enums/EnumTipoEtapa";
import { CaptchaComponent } from "../../shared/components/captcha/captcha.component";

@Component({
  selector: "app-manter-ppb",
  templateUrl: "./manter-ppb.component.html"
})

@Injectable()
export class ManterPPBComponent implements OnInit {

  form = this;
  grid: any = { sort: {} };
  parametros: ConsultarPPBVM = new ConsultarPPBVM();
  ocultarFiltros: boolean = false;
  isBuscaSalva: boolean = false;
  servico = 'ConsultarPPB/ConsultarPPB';
  objetoConsulta: any = {} = null;
  listaTipoDocumento = new Array<any>();
  flagAlterouFiltro: boolean = false;
  isUsuarioExterno: boolean = false;


  isUsuarioVisitante: boolean = false;
  captchaToken: string | null = null;
  showCaptchaMessage = false;



  optTipoPublicacao = [
    {
      label: "Por Pontuação",
      value: EnumTipoEtapa.POR_PONTUACAO
    },
    {
      label: "Sem Pontuação",
      value: EnumTipoEtapa.SEM_PONTUACAO
    },
    {
      label: "PPB Multiplo Sem Pontuação",
      value: EnumTipoEtapa.PPB_MULTIPLO
    },
    {
      label: "Por Grupo Com Pontuação",
      value: EnumTipoEtapa.POR_GRUPO
    }
  ]

  @ViewChild('produtoParaVincular') produtoParaVincular: any;
  @ViewChild('appModalHistorico') appModalHistorico;
  @ViewChild('appModalHistoricoExclusao') appModalHistoricoExclusao;

  @ViewChild(CaptchaComponent) captchaComponent!: CaptchaComponent;

  constructor(
    private changeIniciaPaginacao: ChangeIniciaPaginacao,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private modal: ModalService,
    private msg: MessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private validation: ValidationService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {

    this.renderizarReCaptcha();

    if (sessionStorage.getItem(this.router.url) == 'null' && sessionStorage.length > 0) {
      sessionStorage.clear();

    }
  }


  async ngOnInit() {

    await this.validarUsuario();
    this.preencheComboboxTipoDocumento();

    if (this.isUsuarioVisitante) {
      return;
    }
    else {
      this.retornaValorSessao();
    }


    if (this.isBuscaSalva) {
      this.buscar(true);
    }

    this.parametros.exportarListagem = false;

  }

  @ViewChild(CaptchaComponent) captcha: CaptchaComponent;
	@ViewChild('divHcaptcha') divHcaptcha: ElementRef;

  get hcaptcha(): any {
		const w = window as any;
		return w['hcaptcha'];
	}


  async validarUsuario() {
    try {

      if (window.location.href.includes("consultarppb"))
        this.isUsuarioVisitante = true;

      let loginAnon = sessionStorage.getItem("anon");

      if (loginAnon == "true") {
        this.isUsuarioExterno = true;
      } else {

        var listaPerfis = await this.applicationService.get<Array<number>>('UsuarioLogado/perfilUsuario').toPromise();

        if (listaPerfis != null) {
          var filterPerfis = listaPerfis.filter(item => item == EnumPerfil.analistaMppb || item == EnumPerfil.coordenadorMppb);
          if (filterPerfis.length == 0)
            this.isUsuarioExterno = true;
          else
            this.isUsuarioExterno = false;
        }
      }

    } catch (error) {
      console.error(error)
      this.isUsuarioExterno = true;
    }
  }

  onChangeSort($event: any) {
    this.grid.sort = $event;
    this.buscar(false);
  }

  onChangeSize($event: any) {
    this.grid.size = $event;
    this.grid.page = 1;
    this.buscar(false);
  }

  onChangePage($event: any) {
    this.grid.page = $event;
    this.buscar(false);
  }
  validar(): boolean {

    let params = this.parametros;
    if (!params.PalavraChave) {
      return true;
    }

    if (params.PosicaoPalavra === 0) {
      this.modal.informacao("Para buscar por Palavra Chave, é preciso escolher uma das opções abaixo", "Alerta");
      return false;
    }

    params.PosicaoPalavra = Number(params.PosicaoPalavra);
    return true;
  }

  buscar(getSessionStorage: boolean) {

    let objeto: any = {};

    if (getSessionStorage) {
      this.grid.page = this.flagAlterouFiltro ? 1 : this.parametros.page || 1;
      this.grid.size = this.parametros.size || 10;
      this.grid.sort.field = this.parametros.sort;
      this.grid.sort.reverse = this.parametros.reverse;
    } else {
      if (this.flagAlterouFiltro) {
        this.parametros.page = 1;
        this.parametros.size = 10;
        this.grid.page = 1;
        this.grid.size = 10;

        //reinicia a paginacao dentro de app-grid
        this.changeIniciaPaginacao.Reinicia(true);
      } else {
        this.parametros.page = this.grid.page;
        this.parametros.size = this.grid.size;
      }
      this.parametros.sort = this.grid.sort.field;
      this.parametros.reverse = this.grid.sort.reverse;
    }

    if (this.parametros.Numero && this.parametros.Numero.toString().indexOf(".") != -1) {
      this.modal.informacao("O Número do PPB não pode conter Vírgula <b>','</b>.", "Atenção")
      return false;
    }

      objeto = {
        page: this.flagAlterouFiltro ? 1 :
          this.grid.page || 1,
        size: this.grid.size || 10,
        sort: this.grid.sort.field || "Id",
        reverse: this.grid.sort.reverse || false,
        TipoPublicacao: this.parametros.TipoPublicacao,
        TipoDocumento: this.parametros.TipoDocumento,
        PosicaoPalavra: this.parametros.PosicaoPalavra,
        Situacao: this.parametros.Situacao,
        PalavraChave: this.parametros.PalavraChave,
        Numero: this.parametros.Numero ?
          Number(this.parametros.Numero) :
          null,
        Ano: this.parametros.Ano,
        IdProduto: this.parametros.idProduto,
        TipoEtapa: this.parametros.TipoEtapa,
        DescricaResumoTecnico: this.parametros.DescricaResumoTecnico

   
      }


    if (this.validar()) {

     this.flagAlterouFiltro = false;
      if (this.isUsuarioVisitante) {

          if (this.captchaToken){

           this.applicationService.get(this.servico, objeto).subscribe((response: PagedItems) => {
             if (response.total != null && response.total > 0) {
               this.grid.lista = response.items;
               this.grid.total = response.total;
               this.gravarBusca(objeto);
               this.prencheParametrosDeExportacao();
               this.cdr.detectChanges();
             } else {
               this.grid = { sort: {} };
               this.parametros.exportarListagem = false;
               this.modal.infoError(this.msg.NENHUM_REGISTRO_ENCONTRADO, "Alerta");
             }
           });

         }
         else{

          this.grid = { sort: {} };
          this.parametros.exportarListagem = false;
          this.showCaptchaMessage = true

         }

     }
     else {

       this.applicationService.get(this.servico, objeto).subscribe((response: PagedItems) => {
         if (response.total != null && response.total > 0) {

          this.grid.lista = response.items;
          this.grid.total = response.total;
           this.gravarBusca(objeto);
           this.prencheParametrosDeExportacao();
         } else {
           this.grid = { sort: {} };
            this.parametros.exportarListagem = false;
            this.modal.infoError(this.msg.NENHUM_REGISTRO_ENCONTRADO, "Alerta");
          }
        });

      }

    }

  }

  abrirModal() {
    this.modal.sucesso("Os Produtos dos ppbs revogados foram migrados ao ppb corrente", "Atenção").subscribe(() => {
      this.modal.informacao("Testee", "Teste");
    })
  }

  limpar() {
    this.produtoParaVincular.clear();
    this.parametros.TipoPublicacao = null;
    this.parametros.TipoDocumento = 0;
    this.parametros.TipoEtapa = 0;
    this.parametros.Situacao = 0;
    this.parametros.idProduto = 0;
    this.parametros.Numero = null;
    this.parametros.Ano = null;
    this.parametros.PalavraChave = null;
    this.parametros.PosicaoPalavra = null;
  }

  gerarNovoPPB() {
    this.modal.confirmacao("Deseja Cadastrar um Novo Processo Produtivo Básico?").subscribe((isConfirmado: any) => {
      if (isConfirmado) {
        this.router.navigate(['/solicitacoes-ppb/novo']);
      }
    });
  }

  prencheParametrosDeExportacao() {

    //this.formatarItensDaLista(this.grid.lista);
    this.parametros.lista = this.grid.lista;
    this.parametros.exportarListagem = true;
    this.parametros.exportarPdf = true;
    this.parametros.servico = this.servico;
    this.parametros.titulo = "Manter PPB"


    if (this.isUsuarioVisitante)
    {

      this.parametros.columns = [
        'Situação',
        'Documento',
        'Resumo Técnico'
      ];
  
      this.parametros.fields = [
        'descricaoStatus',
        'descricaoTitulo',
        'descricaResumoTecnico'
      ];
  
      this.parametros.width = {
        0: { columnWidth: 75 },
        1: { columnWidth: 270 }, 
        2: { columnWidth: 350 }
      };
    }
    else{

      this.parametros.columns = [
        'Situação',
        'Documento',
        'Publicação',
        'Início Vigência',
        'Tipo de Vínculo',
        'Documento de Refêrencia'
      ];
  
      this.parametros.fields = [
        'descricaoStatus',
        'descricaoTitulo',
        'dataPublicacaoString',
        'dataInicioVigenciaString',
        'definirVinculo',
        'descricaoPPBReferencia'
      ];
  
      this.parametros.width = {
        0: { columnWidth: 75 }, 1: { columnWidth: 300 },
        2: { columnWidth: 70 }, 3: { columnWidth: 80 },
        4: { columnWidth: 100 }, 5: { columnWidth: 150 }
      };
  
    }

  }

  formatDate(date: Date) {
    return this.validation.getDateWhithChangeFormat('DD/MM/YYYY', date)
  }

  retornaValorSessao(): any {
    var router = sessionStorage.getItem(this.router.url);
    if (router) {
      this.isBuscaSalva = true;
      this.parametros = JSON.parse(sessionStorage.getItem(this.router.url));
      this.parametros.Numero === 0 ? this.parametros.Numero = null : ''
      return this.parametros;
    } else {
      this.isBuscaSalva = false;
      return null;
    }
  }

  gravarBusca(objeto: any) {
    sessionStorage.removeItem(this.router.url);
    sessionStorage.setItem(this.router.url, JSON.stringify(objeto));
  }

  preencheComboboxTipoDocumento() {
    this.applicationService.get("DocumentoMppb").subscribe((retorno: any) => {
      if (retorno != null) {
        this.listaTipoDocumento = retorno;
      }
    });
  }

  abrirHistorico() {
    this.appModalHistoricoExclusao.abrirFromExclusaoPpb();
  }

  isProdutoDisabled(): boolean {
    return this.parametros.Situacao == 4;
  }

  alternarFiltros(): void {
    this.ocultarFiltros = !this.ocultarFiltros;
}
  

  renderizarReCaptcha() {

		this.ngZone.runOutsideAngular(() => {

		  if (!this.hcaptcha || !this.divHcaptcha) {
			setTimeout(() => {
			  this.renderizarReCaptcha();
			}, 500);
	
			return;
		  }
	
		  const idElemento = this.divHcaptcha.nativeElement.getAttribute('id');
	
		  var widgetID = this.hcaptcha.render(idElemento, {
			  sitekey: 'd7f5572b-efd3-457f-933f-51366095eb5f',
			callback: (response: any) => {
			  this.captchaToken = response;
        setInterval(() => {
          this.resetCaptcha();  // Reset do captcha após 2 minutos
        }, 120000);

			},
		  });
	
		});
	  }

    resetCaptcha(): void {
      this.captchaToken = null;
      this.hcaptcha.reset();

      this.grid = { sort: {} };
      this.parametros.exportarListagem = false;
  
    }
    
  


}
