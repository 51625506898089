import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ApplicationService } from "../../../../shared/services/application.service";
import { MessagesService } from "../../../../shared/services/messages.service";
import { ModalService } from "../../../../shared/services/modal.service";
import { PPBVM } from "../../../../view-model/PPBVM";
import {GridManterPPBComponent} from "../../grid/grid.component";
import { GrupoPPBVM } from "../../../../view-model/GrupoPPBVM";

@Component({
	selector: 'app-modal-vinculos',
	templateUrl: './modal-vinculos.component.html'
})

export class ModalVinculos implements OnInit {

  @ViewChild('appModalVinculos') appModalVinculos;
	@ViewChild('appModalVinculosBackground') appModalVinculosBackground;
	@ViewChild('collapse1') collapse1;
	@ViewChild('collapse2') collapse2;
  @ViewChild('appModalEtapasCondicoesPPB') appModalEtapasCondicoesPPB;
	idPPB: number;

  ppb: PPBVM = new PPBVM;

	flagHabilitaComponenteTextual : boolean = false;
	config: AngularEditorConfig = {
		editable: this.flagHabilitaComponenteTextual,
		height: '700px',
		minHeight: '100px',
		placeholder: 'Enter text here...',
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		toolbarHiddenButtons: [
		  ['bold']
		],
		customClasses: [
		  {
			name: "quote",
			class: "quote",
		  },
		  {
			name: 'redText',
			class: 'redText'
		  },
		  {
			name: "titleText",
			class: "titleText",
			tag: "h1",
		  },
		]
	};
	servico: string = "ModalVinculos";
	listaPortaria: any = {length: 0};
	listaAgregacao: any = {length: 0};
	listaResumoTecnico: any = { total: 0, sort: {} };
	parametros: any = {};
	titulo : string = "";
	buscarVinculos = true;
	habilitarInputs = false;

	constructor(
		private applicationService: ApplicationService,
		private modal: ModalService,
		private msg: MessagesService,
		private route: ActivatedRoute,
    private router: Router,
    private listagem: GridManterPPBComponent
	) {
    }

    ngOnInit (){
    }

	abrir(objeto : PPBVM){
		this.idPPB = objeto.id;
		this.buscarDados(this.idPPB);
		this.titulo = objeto.descricaoTitulo;
		this.appModalVinculosBackground.nativeElement.style.display = 'block';
		this.appModalVinculos.nativeElement.style.display = 'block';
    }

	buscarDados(idPPB?){
		this.parametros.idPPB = idPPB || this.idPPB;

		this.parametros.page = this.listaResumoTecnico.page ? this.listaResumoTecnico.page : 1
		this.parametros.size = this.listaResumoTecnico.size ? this.listaResumoTecnico.size : 10
		this.parametros.sort = this.listaResumoTecnico.sort.field || ''
		this.parametros.reverse = this.listaResumoTecnico.sort.reverse || false

		this.applicationService.get(this.servico, this.parametros).subscribe((res:any)=>{
			if (!res.msgErro){
				this.listaPortaria = res.listaVinculosDiferenteAgregacao || 0;
				this.listaAgregacao = res.listaVinculosTipoAgregacao || 0;
				this.listaResumoTecnico = res.listaResumoTecnicoDoc || 0;
				this.listaResumoTecnico.total = res.listaResumoTecnicoDoc.total;
			}else{
				this.modal.infoError(res.msgErro, 'Atenção');
			}
		});
	}

	onChangeSort($event : any) {
		this.listaResumoTecnico.sort = $event;
		this.buscarDados();
	}

	onChangeSize($event : any) {
		this.listaResumoTecnico.size = $event;
    this.listaResumoTecnico.page = 1;
		this.buscarDados();
	}

	onChangePage($event : any) {
		this.listaResumoTecnico.page = $event;
		this.buscarDados();
	}

    fechar(){
		this.limpardados();
        this.appModalVinculosBackground.nativeElement.style.display = 'none';
		this.appModalVinculos.nativeElement.style.display = 'none';
    }
	limpardados() {
		this.listaPortaria = {length: 0};
		this.listaAgregacao = {length: 0};
		this.listaResumoTecnico = { total: 0, sort: {} };
		this.parametros ={};
		this.titulo = "";
	}

  abrirPPB(item){
    item.id = item.ppb.id;
    this.appModalEtapasCondicoesPPB.abrir(item, true);
  }
  abrirListagens(objeto) {
    this.appModalEtapasCondicoesPPB.abrir(objeto);
  }
}
