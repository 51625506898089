/* IMPORTAÇÃO DE MODULOS */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DropListSelect2Component } from '../../shared/components/drop-list-2/drop-list-2.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule } from "ng2-currency-mask";

/* COMPONENTES CADASTRO PPB HISTORIA */
import { GridManterPPBComponent } from './grid/grid.component';
import { ManterPPBComponent } from './manter-ppb.component';
import { ControleSolicitacoesComponent } from './solicitacoes-ppb/controle-solicitacoes-ppb.component';
import { CondicionantesPPBComponent } from './solicitacoes-ppb/condicionantes-ppb/condicionantes-ppb.component';
import { GridCondicionantesPPBComponent } from './solicitacoes-ppb/condicionantes-ppb/grid/grid.component';
import { ControleEtapasPPBComponent } from './solicitacoes-ppb/etapas-ppb/etapas-ppb.component';
import { GridEtapasPPBComponent } from './solicitacoes-ppb/etapas-ppb/grid/grid.component';
import { ModalIncluirEtapasPPBAtuacao } from './solicitacoes-ppb/etapas-ppb/modal/modal-incluir-etapas-ppb.component';
import { ModalIncluirCondicionantesPPBAtuacao } from './solicitacoes-ppb/condicionantes-ppb/modal/modal-incluir-condicionantes-ppb.component';
import { VisualizarCadastrosDePPBComponent } from './visualizar-cadastros-de-ppb/visualizar-cadastros-de-ppb.component';
import { ProdutosVinculadosPPBComponent } from './visualizar-cadastros-de-ppb/produtos-vinculados-ppd/produtos-vinculados-ppb.component';
import { GridProdutosVinculadosAoPPBComponent } from './visualizar-cadastros-de-ppb/produtos-vinculados-ppd/grid/grid.component';
import { ModalEtapasCondicoesPPBAtuacao } from './modais/listagens-ppb/modal-listagens-ppb.component';
import { ModalFinalizar } from './modais/vincular-produto/modal-vincular-produtos.component';
import { ModalVinculos } from './modais/vinculos/modal-vinculos.component';
import { GruposPPBComponent } from './solicitacoes-ppb/grupos-ppb/grupos-ppb.component';
import { ModalIncluirGruposPPBComponent } from './solicitacoes-ppb/grupos-ppb/modal/modal-incluir-grupos-ppb.component';
import { GridGruposPPBComponent } from './solicitacoes-ppb/grupos-ppb/grid/grid.component';
import { GridVinculosPPBComponent } from './modais/vinculos/grid/grid.component';
import { RelatorioListagensPPBComponent } from './solicitacoes-ppb/relatorio-listagens-ppb/relatorio-listagens-ppb.component';
import { ModalHistoricoComponent } from './modais/modal-historico/modal-historico.componet';
import { GridHistoricoPPBComponent } from './modais/modal-historico/grid-historico/grid-historico.component';
import { ModalIncluirMetasPPBComponent } from './solicitacoes-ppb/metas-ppb/modal/modal-incluir-metas-ppb.component';
import { MetasPPBComponent } from './solicitacoes-ppb/metas-ppb/metas-ppb.component';
import { GridMetasPPBComponent } from './solicitacoes-ppb/metas-ppb/grid/grid.component';
import { ModalBaixarAnexos } from './modais/anexos/modal-anexos.component';
import { ProdutosVinculadosComponent } from './modais/listagens-ppb/produtos-ppb/produtos-vinculados.component';
import { GridProdutosComponent } from './modais/listagens-ppb/produtos-ppb/grid/grid.component';

import { PPBMultiploComponent } from './solicitacoes-ppb/ppb-multiplo/ppb-multiplo.component';
import { ModalIncluirPPBMultiploComponent } from './solicitacoes-ppb/ppb-multiplo/modal/modal-incluir-ppb-multiplo.component';
import { GridPPBMultiploComponent } from './solicitacoes-ppb/ppb-multiplo/grid/grid.component';
import { ModalMoverPorSelecao } from './modais/mover-por-selecao/mover-por-selecao.component';
import { Grid2ProdutosComponent } from './modais/vincular-produto/grid/grid.component';
import { GridMoverSelecao } from './modais/mover-por-selecao/grid/move-por-selecao-grid.component';
import { GridHistoricoListagensPPBComponent } from './modais/modal-historico/grid-historico/grid-historico-listagem.component';
import { ModalHistoricoListagensComponent } from './modais/modal-historico-listagens/modal-historico-listagens.componet';
import { GridHistoricoListagemComponent } from './modais/modal-historico-listagens/grid-historico/grid-historico.component';
import { AppListarHistoricoListagensComponent } from './modais/modal-historico-listagens/app-listagens-historico-listagens/app-listagens-historico-listagens.component';
import { ModalHistoricoExclusaoComponent } from './modais/modal-historico-exclusao/modal-historico-exclusao.component';
import { GridEtapaProdutosComponent } from './modais/etapas-condicoes/produtos-ppb/grid/grid.component';
import { CabecalhoRelatorioPPBComponent } from './solicitacoes-ppb/relatorio-listagens-ppb/cabecalho-relatorio/cabecalho-relatorio.component';
import { GridDocReferenciaComponent } from './modais/vinculos/grid-doc-referencia/grid-doc-referencia.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CronogramaPontuacaoPPBComponent } from './solicitacoes-ppb/cronograma-pontuacao-minima/cronograma-pontuacao-ppb.component';
import { GridCronogramaPontuacaoPPBComponent } from './solicitacoes-ppb/cronograma-pontuacao-minima/grid-cronograma/grid.component';
import { ModalIncluirCronogramaComponent } from './solicitacoes-ppb/cronograma-pontuacao-minima/modal/modal-incluir-cronograma.component';
import { GridEtapaCronogramaComponent } from './solicitacoes-ppb/cronograma-pontuacao-minima/grid-cronograma/grid-etapa-cronograma/grid-etapa-cronograma.component';
import { GridCronogramaSemGrupoComponent } from './solicitacoes-ppb/cronograma-pontuacao-minima/grid-cronograma/grid-cronograma-sem-grupo/grid-cronograma-sem-grupo.component';
import { ModalEditarCronogramaComponent } from './solicitacoes-ppb/cronograma-pontuacao-minima/modal/modal-editar-cronograma/modal-editar-cronograma.component';


export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		SharedModule,
		AngularEditorModule,
		NgbModule,
		NgxMaskModule.forRoot(),
		CurrencyMaskModule
	],
	declarations: [
		GridEtapaProdutosComponent,
		ModalHistoricoExclusaoComponent,
		DropListSelect2Component,
		GridManterPPBComponent,
		ManterPPBComponent,
		ControleSolicitacoesComponent,
		CondicionantesPPBComponent,
		GridCondicionantesPPBComponent,
		ControleEtapasPPBComponent,
		GridEtapasPPBComponent,
		ModalIncluirEtapasPPBAtuacao,
		ModalIncluirCondicionantesPPBAtuacao,
		VisualizarCadastrosDePPBComponent,
		ProdutosVinculadosPPBComponent,
		GridProdutosVinculadosAoPPBComponent,
		ModalEtapasCondicoesPPBAtuacao,
		ModalFinalizar,
		ModalVinculos,
		GruposPPBComponent,
		ModalIncluirGruposPPBComponent,
		GridGruposPPBComponent,
		GridVinculosPPBComponent,
		RelatorioListagensPPBComponent,
		ModalHistoricoComponent,
		GridHistoricoPPBComponent,
		ModalIncluirMetasPPBComponent,
		MetasPPBComponent,
		GridMetasPPBComponent,
		ModalBaixarAnexos,
		ProdutosVinculadosComponent,
		GridProdutosComponent,
		PPBMultiploComponent,
		ModalIncluirPPBMultiploComponent,
		GridPPBMultiploComponent,
		Grid2ProdutosComponent,
		ModalMoverPorSelecao,
		ModalHistoricoListagensComponent,
		GridMoverSelecao,
		GridHistoricoListagensPPBComponent,
		ModalHistoricoListagensComponent,
		GridHistoricoListagemComponent,
		AppListarHistoricoListagensComponent,
		CabecalhoRelatorioPPBComponent,
		GridDocReferenciaComponent,
		CronogramaPontuacaoPPBComponent,
		GridCronogramaPontuacaoPPBComponent,
		GridCronogramaSemGrupoComponent,
		ModalIncluirCronogramaComponent,
		GridEtapaCronogramaComponent,
		ModalEditarCronogramaComponent
	],
})
export class ManterPBModule { }
