import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ApplicationService } from "./shared/services/application.service";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { ModalService } from './shared/services/modal.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public usuario: string = "";
  public senha: string = "";
  public isExibirLogin: boolean = false;
  public mensagem: string = "";
  public ano: number = 0;
  @Input() authenticated: boolean = false;
  public usuarioVisitante: boolean = false; // Variável para controlar a exibição do ConsultarPPB

  constructor(
    private modal: ModalService,
    private titleService: Title,
    private meta: Meta,
    private applicationService: ApplicationService,
    private cdr: ChangeDetectorRef,
    private router: Router, // Importar o Router para monitorar mudanças de rota
  ) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  click() {
    this.applicationService.post("Login", { usuario: this.usuario, senha: this.senha }).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.mensagem = 'Login realizado com sucesso';
        this.applicationService.setToken(data.token);
        this.authenticated = true;
      } else if (data.statusCode == 403) {
        this.authenticated = false;
        this.modal.infoError("Usuário não Encontrado! Verifique o Login e Senha e tente novamente.", "Atenção").subscribe(() => { window.location.reload(); });
      } else {
        this.authenticated = false;
        this.modal.error(data.message, "Falha ao Efetuar Login - " + data.statusCode).subscribe(() => { window.location.reload(); });
      }
    });
  }



  ngOnInit() {
    // Verifica se já existe um token armazenado
  
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      this.usuarioVisitante = event.url.includes('consultarppb');

      if (this.usuarioVisitante){
        this.authenticated = true;
        this.applicationService.removeToken();
        
      }
      else if (this.applicationService.getToken()) {
        this.authenticated = true;
      }
      else{
        this.redenrizarLogin();
        this.cdr.detectChanges();
      } 

    });

  }
  

  
  logout($event) {
    this.authenticated = $event; 
    this.applicationService.removeToken();
    this.redenrizarLogin();

  }


  redenrizarLogin(){

    this.meta.addTag({ name: 'description', content: 'Sistema de Ingresso de Mercadoria Nacional' });
    this.meta.addTag({ name: 'keywords', content: 'ingresso, suframa, simnac, mercadoria, nacional' });
    this.titleService.setTitle("PRJ - Mppb");
    this.isExibirLogin = environment.developmentMode;

    this.ano = new Date().getFullYear();

    let hash = window.location.hash.toLowerCase();

    var certificado = false;

    if (window.location.hash.includes("?cert=true")) {
      certificado = true;
    } else if (window.location.href.includes("acesso-anonimo")) {
      window.location.replace(environment.serviceUrl + 'CertificadoAnonRedirect');
    } else if (window.location.href.includes("anonimo=true")) {
      certificado = true;
    }
    else {
      window.location.replace(environment.serviceUrl + 'CertificadoRedirect');
    }

    if (!window.location.href.includes("anonimo=true")) {
      sessionStorage.removeItem("anon");
      this.applicationService.removeToken();
      document.body.style.backgroundImage = "url('assets/images/bg.png')";

      if (window.location.search.startsWith("?ticket=")) {
        window.location.replace(environment.mppbUrl);
      } else if (certificado == true && window.location.search.startsWith("?token=")) {
        var token = window.location.search.substr(7);
        this.applicationService.setToken(token);
        this.authenticated = true;
      }
    } else {
      this.authenticated = true;
      this.usuario = "anon";
      this.senha = "anon";
      sessionStorage.setItem("anon", "true");
      this.click();
    }
  }
}
