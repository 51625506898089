<div class="slim-scroll padder-h" data-height="auto" data-disable-fade-out="true" data-distance="0" data-size="10px"
  data-railopacity="0.2">
  <div class="row m-b-md">
    <div class="col-sm-6">
      <app-titulo>Consultar PPB</app-titulo>
      <app-sub-titulo>Consultar</app-sub-titulo>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <section class="panel panel-default">
        <header class="panel-heading">

          <div class="pull-right" *ngIf="!isUsuarioVisitante">
            <a *ngIf="!isUsuarioExterno" (click)="gerarNovoPPB()" class="btn btn-primary btn-sm">
              <i class="fa fa-plus"></i> Novo
            </a>
          </div>
          <h2 class="panel-title h5">Filtros de Pesquisa</h2>
        </header>


        <!-- Interno -->
        <div *ngIf="!isUsuarioVisitante">
          <article *ngIf="!ocultarFiltros" class="panel-body">
            <form id="formBusca" class="form form-horinzontal" role="form" #formBusca="ngForm" ngNativeValidate>


              <article class="panel-body" *ngIf="!isUsuarioVisitante">

                <div class="row form-group">
                  <!-- Tipo de Publicação -->
                  <div class="col-lg-4">
                    <label for="descricao" class="">Tipo de Publicação:</label>
                    <select name="tipo-publicacao-solicitacoes-ppb" id="tipo-publicacao-solicitacoes-ppb"
                      class="form-control" [(ngModel)]="parametros.TipoPublicacao" (change)="flagAlterouFiltro = true">
                      <option value="0"> Selecione uma opção ... </option>
                      <option value="1"> FIXAÇÃO (NOVO) </option>
                      <option value="2"> ALTERAÇÃO POR MEIO DE REVOGAÇÃO </option>
                      <option value="3"> ALTERAÇÃO PARCIAL </option>
                      <option value="4"> NORMA COMPLEMENTAR </option>
                      <option value="5"> FIXAÇÃO DE PPB (Genérico para Especifico) </option>
                    </select>
                  </div>

                  <!-- Tipo de Documento -->
                  <div class="col-lg-4">
                    <label for="descricao" class="">Tipo de Documento:</label>
                    <select *ngIf="listaTipoDocumento.length" class="form-control"
                      [(ngModel)]="parametros.TipoDocumento" name="tipo-de-Documento"
                      (change)="flagAlterouFiltro = true" id="tipo-de-Documento">
                      <option value="0"> Selecione uma opção ... </option>
                      <option *ngFor="let item of listaTipoDocumento" [ngValue]="item.id">
                        {{ item.descricao }}
                      </option>
                    </select>
                  </div>

                  <!-- Situação -->
                  <div class="col-lg-4">
                    <label for="situacao" class="">Situação:</label>
                    <select name="situacao" id="situacao" class="form-control" (change)="flagAlterouFiltro = true"
                      [(ngModel)]="parametros.Situacao">
                      <option value="0" ng-reflect-value="">Selecione...</option>
                      <option value="1" *ngIf="!isUsuarioExterno">Em Elaboração</option>
                      <option value="2">Vigente</option>
                      <option value="3">Vigência Futura</option>
                      <option value="4">Revogado</option>
                      <option value="5">Em Retificação</option>
                    </select>
                  </div>
                </div>

                <div class="row form-group">
                  <!-- Produto para Vincular -->
                  <div class="col-sm-8">
                    <label for="descricao">Produto para Vincular:</label>
                    <app-drop-list-select2 #produtoParaVincular id="produto-para-vincular"
                      (change)="flagAlterouFiltro = true" name="produto-para-vincular"
                      (valorModificado)="parametros.idProduto = $event;" servico="ViewMccpProdutoNcm"
                      [placeholder]="'Pesquisar Produto (Código Produto, Código NCM ou Descrição)'">
                    </app-drop-list-select2>
                  </div>

                  <!-- Tipo de Etapa -->
                  <div class="col-sm-4">
                    <label for="tipo-etapa">Tipo de Etapa:</label>
                    <select id="tipo-etapa" name="tipo-etapa" class="form-control" [(ngModel)]="parametros.TipoEtapa"
                      (change)="flagAlterouFiltro = true">
                      <option value="0">Todos</option>
                      <option *ngFor="let item of optTipoPublicacao" [value]="item.value">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row form-group">
                  <!-- Número -->
                  <div class="col-lg-3 text-nowrap">
                    <label for="codigo">Número:</label>
                    <input (change)="flagAlterouFiltro = true" type="text" name="codigo" id="codigo"
                      (keyup.enter)="buscar(false)" [(ngModel)]="parametros.Numero" class="form-control"
                      mask="separator" thousandSeparator="." maxlength="13" />
                  </div>

                  <!-- Ano -->
                  <div class="col-lg-2">
                    <label for="descricao" class="">Ano:</label>
                    <input (change)="flagAlterouFiltro = true" type="text" name="ano" id="ano"
                      (keyup.enter)="buscar(false)" [(ngModel)]="parametros.Ano" class="form-control" mask="0000" />
                  </div>

                  <!-- Palavra-chave -->
                  <div class="col-lg-7 text-nowrap">
                    <label for="codigo">Palavra-chave:</label>
                    <input (change)="flagAlterouFiltro = true" type="text" name="palavra" id="palavra"
                      [(ngModel)]="parametros.PalavraChave" (keyup.enter)="buscar(false)" class="form-control" />
                    <div class="radio i-checks radio-sm" style="margin-right: 1px;">
                      <label class="input-sm">
                        <input (change)="flagAlterouFiltro = true" name="radio[custom]" type="radio"
                          [(ngModel)]="parametros.PosicaoPalavra" value="1"><i></i> Começa por
                      </label>
                      <label class="input-sm">
                        <input (change)="flagAlterouFiltro = true" name="radio[custom]" type="radio"
                          [(ngModel)]="parametros.PosicaoPalavra" value="2"><i></i> Qualquer parte
                      </label>
                      <label class="input-sm">
                        <input (change)="flagAlterouFiltro = true" name="radio[custom]" type="radio"
                          [(ngModel)]="parametros.PosicaoPalavra" value="3"><i></i> Palavra Exata
                      </label>
                    </div>
                  </div>
                </div>

              </article>

            </form>
          </article>
        </div>

        <!-- Visitante -->
        <div *ngIf="isUsuarioVisitante" [ngStyle]="{ 'display': ocultarFiltros ? 'none' : 'block' }">
          <article class="panel-body">
            <form id="formBusca" class="form form-horinzontal" role="form" #formBusca="ngForm" ngNativeValidate>


              <article class="panel-body" *ngIf="isUsuarioVisitante">
                <div>
                  <div class="row form-group">
                    <!-- Produto para Vincular -->
                    <div class="col-12 col-md-8">
                      <label for="descricao">Produto:</label>
                      <app-drop-list-select2 #produtoParaVincular id="produto-para-vincular"
                        (change)="flagAlterouFiltro = true" name="produto-para-vincular"
                        (valorModificado)="parametros.idProduto = $event;" servico="ViewMccpProdutoNcm"
                        [placeholder]="'Pesquisar Produto (Código Produto, Código NCM ou Descrição)'"
                        [isDisabled]="isProdutoDisabled()"></app-drop-list-select2>
                    </div>

                    <!-- Situação -->
                    <div class="col-12 col-md-4">
                      <label for="situacao">Situação:</label>
                      <select name="situacao" id="situacao" class="form-control" (change)="flagAlterouFiltro = true"
                        [(ngModel)]="parametros.Situacao">
                        <option value="0">Selecione...</option>
                        <option value="2">Vigente</option>
                        <option value="3">Vigência Futura</option>
                        <option value="4">Revogado</option>
                      </select>
                    </div>
                  </div>

                  <div class="row form-group">
                    <!-- Número -->
                    <div class="col-12 col-md-3 text-nowrap">
                      <label for="codigo">Número:</label>
                      <input (change)="flagAlterouFiltro = true" type="text" name="codigo" id="codigo"
                        (keyup.enter)="buscar(false)" [(ngModel)]="parametros.Numero" class="form-control"
                        mask="separator" thousandSeparator="." maxlength="13" />
                    </div>

                    <!-- Ano -->
                    <div class="col-12 col-md-2">
                      <label for="ano">Ano:</label>
                      <input (change)="flagAlterouFiltro = true" type="text" name="ano" id="ano"
                        (keyup.enter)="buscar(false)" [(ngModel)]="parametros.Ano" class="form-control" mask="0000" />
                    </div>

                    <!-- Palavra-chave -->
                    <div class="col-12 col-md-7 text-nowrap">
                      <label for="palavra">Palavra-chave:</label>
                      <input (change)="flagAlterouFiltro = true" type="text" name="palavra" id="palavra"
                        [(ngModel)]="parametros.PalavraChave" (keyup.enter)="buscar(false)" class="form-control" />
                      <div class="radio i-checks radio-sm" style="margin-right: 1px;">
                        <label class="input-sm">
                          <input (change)="flagAlterouFiltro = true" name="radio[custom]" type="radio"
                            [(ngModel)]="parametros.PosicaoPalavra" value="1" /><i></i> Começa por
                        </label>
                        <label class="input-sm">
                          <input (change)="flagAlterouFiltro = true" name="radio[custom]" type="radio"
                            [(ngModel)]="parametros.PosicaoPalavra" value="2" /><i></i> Qualquer parte
                        </label>
                        <label class="input-sm">
                          <input (change)="flagAlterouFiltro = true" name="radio[custom]" type="radio"
                            [(ngModel)]="parametros.PosicaoPalavra" value="3" /><i></i> Palavra Exata
                        </label>

                        <div class="pull-right d-flex flex-column align-items-center">
                          <div #divHcaptcha id="hcaptcha-container"></div>
                          <small class="text-danger" *ngIf="!captchaToken && showCaptchaMessage">
                            Por favor, valide o captcha.
                          </small>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </article>

            </form>
          </article>
        </div>


        <footer class="panel-footer clearfix" style="padding-top: 5px; padding-bottom: 5px;">

          <div class="pull-right">
            <button *ngIf="!ocultarFiltros" class="btn btn-sm btn-primary" type="submit" disableOnClick
              (click)="buscar(false)">
              <i class="fa fa-search"></i> Buscar
            </button>
            <button *ngIf="!ocultarFiltros" #btnlimpar class="btn btn-sm btn-default" type="reset" (click)="limpar()">
              <i class="fa fa-eraser"></i> Limpar
            </button>
            <button type="button" class="btn btn-sm btn-default" (click)="alternarFiltros()" style="margin-left: -1px;">
              <span class="fa fa-magic m-r-xs"></span>{{ ocultarFiltros ? 'Exibir Filtros' : 'Ocultar Filtros' }}
            </button>

          </div>
        </footer>

      </section>
    </div>
  </div>
  <div class="row" *ngIf="grid.total > 0">
    <div class="col-lg-12">
      <app-grid-manter-ppb [(lista)]="grid.lista" [(total)]="grid.total" [(size)]="grid.size" [(page)]="grid.page"
        [isUsuarioVisitante]="isUsuarioVisitante" [(parametros)]="parametros" (onChangeSort)="onChangeSort($event)"
        (onChangeSize)="onChangeSize($event)" (onChangePage)="onChangePage($event)" (atualizarListagem)="ngOnInit()">
      </app-grid-manter-ppb>
    </div>
  </div>
</div>

<app-modal-historico #appModalHistorico></app-modal-historico>
<app-modal-historico-exclusao #appModalHistoricoExclusao></app-modal-historico-exclusao>