import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { CabecalhoGovernoFederalComponent } from "./layout/cabecalho-governo-federal/cabecalho-governo-federal.component";
import { LayoutModule } from './layout/layout.module';
import { LoadingComponent } from './views-components/loading/loading.component';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import { InterceptorService } from './shared/services/interceptors.service';
import localePt from '@angular/common/locales/pt';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { AppRoutingModule } from './app-routing.module';


registerLocaleData(localePt, 'pt-BR');
declare module "@angular/core" {	interface ModuleWithProviders<T = any> {
	  ngModule: Type<T>;
	  providers?: Provider[];
	}
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule(
    {
	declarations: [
		AppComponent,
		CabecalhoGovernoFederalComponent,
        LoadingComponent,
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		LayoutModule,
		HttpClientJsonpModule,
    NgxMaskModule.forRoot(maskConfig),
		HttpClientModule,
		NgbModule
    ],
	bootstrap: [AppComponent],
	providers: [
		{ provide: LOCALE_ID, useValue: "pt-BR" },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
    ],

})

export class AppModule { }
