<div class="modal-dialog center" style="margin-top: 200px;">
    <div class="modal-content">
      <div class="modal-header bg-primary" style="padding: 10px 15px; color: white;">
        <h4 class="modal-title">Verificação de Segurança:</h4>
      </div>
      <div class="modal-body" style="display: flex; justify-content: center; align-items: center; height: 150px;">
        <div class="hcaptcha-container">
          <!-- O ID 'hcaptcha' é necessário para a renderização do hCaptcha -->
          <div id="hcaptcha" class="h-captcha"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="fecharModal()">
          {{ botaoTexto }} <!-- Exibe o texto do botão conforme a variável -->
        </button>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade in"></div>
  